import React from 'react';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { countOnCallHours, useGetTimeTrackingDaysQueryData } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import { BaseTimeTrackingEntryFragment, SupplementType, TimeTrackingDayStatus, TimeTrackingEntry } from '@ssg/common/GraphQL/indexV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMedkit, faUmbrellaBeach, faWalking } from '@fortawesome/pro-regular-svg-icons';
import { faBell, faBellPlus, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import Table from '@ssg/common/Components/Table';
import SummaryCard from '@ssg/common/Components/SummaryCard';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import UserContext from '../../UserContext';
import AllRegsOverviewFilterNew from './AllRegsOverviewFilterNew';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

export interface TrackingFilters {
	minDate: string;
	maxDate: string;
	view: 'OWN' | 'ALL';
	user: string | undefined;
}

const AllRegistrationsOverviewNew: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const showOnCallHours = useFlag(FeatureFlagEnums.SHOW_ONCALL_HOURS);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const lastMonth = new Date();
	lastMonth.setDate(lastMonth.getDate() - 30);

	const [activeFilters, setActiveFilters] = React.useState<TrackingFilters>({
		minDate: formatDateForInput(lastMonth),
		maxDate: formatDateForInput(new Date()),
		view: 'OWN',
		user: undefined,
	});

	// const users = activeFilters.view === 'OWN' ? [userContext.user?.id ?? 'error'] : activeFilters.user;
	const users = activeFilters.user ? activeFilters.user : [userContext.user?.id ?? 'error'];
	
	const { timeTrackingDaysDataRaw, timeTrackingDaysLoading } = useGetTimeTrackingDaysQueryData(activeFilters.minDate, activeFilters.maxDate, users, TimeTrackingDayStatus.Closed);
	const timeTrackingDays = React.useMemo(() => {
		return timeTrackingDaysDataRaw?.timeTrackingDays ?? [];
	}, [timeTrackingDaysDataRaw]);

	const timeTrackingEntries = React.useMemo(() => {
		return timeTrackingDaysDataRaw?.timeTrackingDays ?? [];
	}, [timeTrackingDaysDataRaw?.timeTrackingDays]);

	const fuse = React.useMemo(
		() =>
			new Fuse(timeTrackingEntries.flatMap(e => e.timeTrackingEntries), {
				shouldSort: true,
				threshold: 0.1,
				keys: ['user.name', 'user.email', 'case.erpNo', 'case.debitor.company', 'jobTaskName', 'addonLines.paymentSupplementName', 'addonLines.paymentSupplementCode'],
			}),
		[timeTrackingEntries],
	);

	const filteredTrackings =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ 'user.name': searchToken },
								{ 'user.email': searchToken },
								{ 'case.erpNo': searchToken },
								{ 'case.debitor.company': searchToken },
								{ 'jobTaskName': searchToken },
								{ 'addonLines.paymentSupplementName': searchToken },
								{ 'addonLines.paymentSupplementCode': searchToken },
								{ jobTaskNo: searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: timeTrackingEntries.flatMap(e => e.timeTrackingEntries) as TimeTrackingEntry[];

	//const totalHours = timeTrackingDays.flatMap(d => d.totalHours).reduce((acc, curr) => acc + curr, 0) ?? 0;

	const totalHours = timeTrackingDays
    	.flatMap(d => d.timeTrackingEntries)
    	.filter(entry => entry.jobTaskNo !== '1902') // Filter out piecework entry hours
    	.reduce((acc, curr) => acc + curr.hours, 0) ?? 0;

	// const totalOvertimeHours = timeTrackingDays.flatMap(e => e.timeTrackingEntries).reduce((acc, curr) => {
	// 	const overtimeAddonLines = curr.addonLines?.filter(line => line.paymentSupplementCode === '8599') ?? [];
	// 	return acc + overtimeAddonLines.reduce((acc, curr) => acc + curr.hours, 0);
	// }, 0) ?? 0;

	// Ferie/feriefri
	// '007', // Feriefridag
	// '008', // Feriedag
	
	// Fravær
	// '004', // Fri for egen regning
	// '005', // Barsel
	// '017', // Hjemsendelse
	// '021', // Skole ophold - IT student
	
	// Sygdom
	// '013', // Fravær pga. arbejdsskade.
	// '002', // Syg
	// '022', // Syg - Langtidssydom
	// '0034', // §56 sygdom

	const vacationArray = ['007', '008'];
	const absenceArray = ['004', '005', '017', '021'];
	const sickLeaveArray = ['013', '002', '022', '0034'];

	const timeTrakingDaysEntries = timeTrackingDays.flatMap(e => e.timeTrackingEntries);

	const totalVacationHours = timeTrakingDaysEntries.reduce((acc, curr) => {
		if (vacationArray.includes(curr.jobTaskNo)) {
			return acc + curr.hours;
		}
		return acc;
	}, 0);

	const totalAbsenceHours = timeTrakingDaysEntries.reduce((acc, curr) => {
		if (absenceArray.includes(curr.jobTaskNo)) {
			return acc + curr.hours;
		}
		return acc;
	}, 0);

	const totalSickLeaveHours = timeTrakingDaysEntries.reduce((acc, curr) => {
		if (sickLeaveArray.includes(curr.jobTaskNo)) {
			return acc + curr.hours;
		}
		return acc;
	}, 0);

	const destructuredEntries = timeTrakingDaysEntries.map(({ jobNo, jobTaskNo, hours, onCall }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
	})) ?? [];

	const destructuredAddonLines = timeTrakingDaysEntries.flatMap(e => e.addonLines).map(({ jobNo, jobTaskNo, hours, onCall, paymentSupplementCode }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
		paymentSupplementCode,
	})) ?? [];

	const onCallHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0601', 2.5);
	const onCallbackHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0600', 3);

	type AccumulatedSupplement = {
		name: string;
		hours: number;
	};
	
	const accumulatedSupplements: AccumulatedSupplement[] = timeTrakingDaysEntries.reduce((acc: AccumulatedSupplement[], curr: BaseTimeTrackingEntryFragment) => {
		const addonLines = curr.addonLines ?? [];
		
		addonLines.forEach((line) => {
			if (line.paymentSupplementCode && line.paymentSupplementName) {
				const key = `${line.paymentSupplementName} (${line.paymentSupplementCode})`;
				const index = acc.findIndex((item: AccumulatedSupplement) => item.name === key);
				if (index === -1) {
					acc.push({ name: key, hours: line.hours });
				} else {
					acc[index].hours += line.hours;
				}
			}
		});

		return acc;
	}, []) ?? [];

	const internRegClasses = 'bg-cyan-calm border-green-dark border-b-1';
	const caseRegClasses = 'bg-blue-lightcalm border-blue-calm border-b-1';

	return (
		<div>
			<BoxContainer>
				<Box full>
					<AllRegsOverviewFilterNew setFilterTerm={setSearchTerm} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />

					<div className='flex space-x-6'>
						<div>
							<h3>{t('timeRegistration.totalHoursInPeriod')}</h3>
							{showOnCallHours
								? (
									<>
										<div className='flex space-x-4 mb-4 mt-1'>
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.hours" data={totalHours} icon={faClock} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="timeRegistration.onCall" data={onCallHours.hours} dataTitle2='Overtid' data2={onCallHours.overtimeHours} icon={faBell} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="timeRegistration.onCallback" data={onCallbackHours.hours} dataTitle2='Overtid' data2={onCallbackHours.overtimeHours} icon={faBellPlus} iconSize='2x' />
										</div>
										<div className='flex space-x-4 mb-4 mt-1'>								
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.vacation" data={totalVacationHours} icon={faUmbrellaBeach} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.absence" data={totalAbsenceHours} icon={faWalking} iconSize='2x' />
											<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.sickLeave" data={totalSickLeaveHours} icon={faMedkit} iconSize='2x' />
										</div>
									</>
								)
								: (
									<div className='flex space-x-4 mb-4 mt-1'>
										<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.hours" data={totalHours} icon={faClock} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.vacation" data={totalVacationHours} icon={faUmbrellaBeach} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.absence" data={totalAbsenceHours} icon={faWalking} iconSize='2x' />
										<SummaryCard loading={timeTrackingDaysLoading} dataTitle="common.sickLeave" data={totalSickLeaveHours} icon={faMedkit} iconSize='2x' />
									</div>
								)
							}
						</div>

						<div>
							<h3>{t('timeRegistration.totalSuppsInPeriod')}</h3>
							<div className="flex flex-wrap mb-4">
								{accumulatedSupplements.length > 0
									? accumulatedSupplements.map((supplement) => 
										<SummaryCard key={supplement.name} small loading={timeTrackingDaysLoading} dataTitle={supplement.name} data={supplement.hours} />,
									)
									: <p className="text-xs">{t('timeRegistration.noSupplementsFound')}</p>
								}
							</div>
						</div>
					</div>

					<Table
						data={filteredTrackings ?? []}
						columns={[
							{
								label: 'common.date',
								numeric: true,
								selectFn: t => <p>{formatDate(new Date(t.startDate))}</p>,
								sortFn: (a, b) => a.startDate.localeCompare(b.startDate),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.name',
								selectFn: t => <p>{t.user.name ? t.user.name : ''}</p>,
								sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.case',
								selectFn: t => (
									<div>
										<div>
											<p>{t.case ? `${t.case.erpNo}` : ''}</p>
											<p>
												{t.case
													? `${t.case.damage.contact.address.road} ${t.case.damage.contact.address.houseNumber}, ${t.case.damage.contact.address.postalCode} ${t.case.damage.contact.address.city}`
													: ''}
											</p>
											<p>{t.case ? `${t.case.damage.category.name}/${t.case.damage.cause.name}` : ''}</p>
										</div>
										<div className="flex flex-row space-x-2 mb-1">
											{t.addonLines.map(supLine => (
												<div key={supLine.id} className="text-xs px-1 border-1 border-black border-opacity-50 rounded-default">
													{`${supLine.paymentSupplementName} (${supLine.paymentSupplementCode}) ${supLine.supplementType === SupplementType.Piecework ? supLine.pieceworkAmount : supLine.hours} ${supLine.supplementUom?.toLowerCase()}`}
												</div>
											))}
										</div>
									</div>
								),
								sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'offer.jobTask',
								selectFn: t => <p>{t.jobTaskName}</p>,
								sortFn: (a, b) => (a.jobTaskName ?? '').localeCompare(b.jobTaskName ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.comment',
								noTruncate: true,
								classNameTh: 'w-1/3',
								selectFn: t => <p>{t.remark}</p>,
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
							{
								label: 'common.hours',
								selectFn: t => <p>{t.hours}</p>,
								sortFn: (a, b) => (a.hours.toString() ?? '').localeCompare(b.hours.toString() ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
								numeric: true,
							},
							{
								label: 'common.indications',
								selectFn: t => (
									<div className="flex items-baseline">
										{t.includesLunch && <FontAwesomeIcon icon={faUtensils} size="sm" className="mr-2" />}
										{(t.onCall && !t.onCallback) && <FontAwesomeIcon icon={faBell} size="sm" className="mr-2" />}
										{t.onCallback && <FontAwesomeIcon icon={faBellPlus} size="sm" />}
									</div>
								),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},	
							{
								label: 'common.debitor',
								selectFn: t => <p>{t.case?.debitor.company}</p>,
								sortFn: (a, b) => (a.case?.debitor.company ?? '').localeCompare(b.case?.debitor.company ?? ''),
								dynamicClassNameTh: t => (t && t.jobNo === 'INTERN') ? internRegClasses : caseRegClasses,
							},
						]}
						keySelector={t => t.id}
						noDataFoundText="timeRegistration.noTimeRegFound"
						loading={timeTrackingDaysLoading}
					/>
				</Box>
			</BoxContainer>
		</div>
	);
};

export default AllRegistrationsOverviewNew;
