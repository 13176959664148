import React, { Fragment } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faLock, faLockOpen, faMinus, faMinusSquare, faPlus, faPlusSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import {
	GetScreeningTemplates,
	ScreeningLineType,
	UpdateScreening,
	UpdateScreeningVariables,
	CreateScreening,
	CreateScreeningVariables,
	DeleteScreening,
	DeleteScreeningVariables,
	GetWebScreeningPositionTexts,
	GetWebScreeningPositionTextsVariables,
	GetScreeningTemplates_screeningTemplates_lines,
	UnlockScreening,
	UnlockScreeningVariables,
	GetDebitor_debitor,
} from '../../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { Heading, TemplateLine, MappedLine, getScreeningLines } from './helpers';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Radio from '@ssg/common/Components/Radio';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import Input from '@ssg/common/Components/Input';
import DynamicInput from './DynamicInput';
import DateRangeInput from './DateRangeInput';
import useToggleSet from '@ssg/common/Hooks/useToggleSet';
import Dropdown from '@ssg/common/Components/Dropdown';
import classNames from 'classnames';
import Tabs from '@ssg/common/Components/Tabs';
import TextButton from '@ssg/common/Components/TextButton';
import {
	shouldCalculateEnvironmentalFeeForDebitor,
	//hasMaximumEnvironmentalFeeAmountForDebitor,
	calculateEnvironmentalFeeForLines,
	calculateEnvironmentFeeScreening,
	DEFAULT_ENVFEE_NAME,
	hasMaximumEnvironmentalFeeAmountForDebitor,
} from '../../../environmentalFeeHelper';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import ScreeningSuggestions from './ScreeningSuggestions';
//import UserContext from '../../../UserContext';

const GET_SCREENING_TEMPLATES = loader('../../../GraphQL/Screening/GetScreeningTemplates.gql');
const GET_SCREENING_POSITION_TEXTS = loader('../../../GraphQL/Screening/GetWebScreeningPositionTexts.gql');
const CREATE_SCREENING = loader('../../../GraphQL/Screening/CreateScreening.gql');
const UPDATE_SCREENING = loader('../../../GraphQL/Screening/UpdateScreening.gql');
const UNLOCK_SCREENING = loader('../../../GraphQL/Screening/UnlockScreening.gql');
const DELETE_SCREENING = loader('../../../GraphQL/Screening/DeleteScreening.gql');

export interface AllScreeningsType {
	id: string;
	name: string;
	locked: boolean;
	reopenAllowed: boolean;
	erpReferenceNo: string;
	template: string;
	templateId: string;
	templateName: string;
	data: Record<string, string>;
	visibleLines: number[];
	lines: TemplateLine[];
	rootLines: Heading[];
	mappedLines: MappedLine[];
}

export interface MovableValues {
	movableVolume: number | null;
	movableDays: number | null;
	movableDateFrom: string | null;
	movableDateTo: string | null;
}

interface Props {
	caseId: string;
	submitCb: () => unknown;
	submitUnlockCb: () => unknown;
	close: () => unknown;
	visible: boolean;
	defaultScreeningName: string;
	defaultTemplate?: string;
	debitor: GetDebitor_debitor;
	screenings: {
		id: string;
		name: string;
		locked: boolean;
		reopenAllowed: boolean;
		template: string;
		templateId: string;
		templateName: string;
		data: Record<string, string>;
		visibleLines: number[];
		erpReferenceNo: string;
	}[];
}

const NO_SCREENING_TEMPLATE = '__NO_SELECTION__';
const CREATE_SCREENING_PREFIX = 'CREATE_';

// string is an error message
// undefined is no error
export const validateRootHeading = (heading: Heading, mappedLines: MappedLine[]): string | undefined => {
	// A required subline should fail first
	const requiredSubLines = heading.subLines.filter(sl => sl.required);
	if (requiredSubLines.length === 0) {
		return;
	}

	for (const subLine of requiredSubLines) {
		const mappedLineHasValue = Boolean(mappedLines.find(ml => ml.erpReferenceId === subLine.erpReferenceId)?.value);
		const radioMappedLineHasValue = mappedLines.some(ml => ml.value === subLine.id);

		if (!mappedLineHasValue && !radioMappedLineHasValue) {
			return 'case.screeningSubfieldRequiredError';
		}
	}

	if (heading.required) {
		const mappedLineHasValue = Boolean(mappedLines.find(ml => ml.erpReferenceId === heading.erpReferenceId)?.value);
		const radioMappedLineHasValue = mappedLines.some(ml => ml.value === heading.id);

		if (!mappedLineHasValue && !radioMappedLineHasValue) {
			return 'case.screeningSubfieldRequiredError';
		}
	}
};
const ScreeningModal: React.FC<Props> = ({ caseId, submitCb, visible, close, defaultScreeningName, defaultTemplate = NO_SCREENING_TEMPLATE, screenings, submitUnlockCb, debitor }) => {
	const { t } = useTranslation();
	const enabledEnvironmentalFeeFlag = useFlag(FeatureFlagEnums.ENVIRONMENTAL_FEE);
	const unlockScreeningFlag = useFlag(FeatureFlagEnums.UNLOCK_SCREENING);
	const aIInvoicePredictionsScreeningFlag = useFlag(FeatureFlagEnums.AI_INVOICE_PREDICTIONS_SCREENING);

	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);
	const hasMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);

	const [allScreenings, setAllScreenings] = React.useState<AllScreeningsType[]>(
		screenings.map(s => ({
			...s,
			lines: [] as TemplateLine[],
			rootLines: [] as Heading[],
			mappedLines: [] as MappedLine[],
		})),
	);
	const allScreeningNames = React.useMemo(() => allScreenings.map(s => s.name), [allScreenings]);
	const [selectedScreeningId, setSelectedScreeningId] = React.useState<string | undefined>(allScreenings.length > 0 ? allScreenings[0].id : undefined);

	const [deletedScreeningsIdList, setDeletedScreeningsIdList] = React.useState<string[]>([]);

	React.useEffect(() => {
		setAllScreenings(allS => allS.filter(thisS => !deletedScreeningsIdList.includes(thisS.id)));
	}, [deletedScreeningsIdList]);

	const editMode = React.useMemo(() => allScreenings.some(s => s.id === selectedScreeningId), [allScreenings, selectedScreeningId]);

	const [selectedTemplateCode, setSelectedTemplateCode] = React.useState(defaultTemplate);

	const openedLinesHandler = (screeningId: string, lineId: number) =>
		setAllScreenings(current => {
			const index = current.findIndex(s => s.id === screeningId);
			if (index === -1) {
				return current;
			}

			let curentOpenedLines = current[index].visibleLines;

			if (curentOpenedLines.includes(lineId)) {
				curentOpenedLines = curentOpenedLines.filter(c => c !== lineId);
			} else {
				curentOpenedLines.push(lineId);
			}

			return [
				...current.slice(0, index),
				{
					...current[index],
					visibleLines: curentOpenedLines,
				},
				...current.slice(index + 1),
			];
		});

	const toggleAllLines = (screeningId: string, lines: number[]) =>
		setAllScreenings(current => {
			const index = current.findIndex(s => s.id === screeningId);
			if (index === -1) {
				return current;
			}

			return [
				...current.slice(0, index),
				{
					...current[index],
					visibleLines: lines,
				},
				...current.slice(index + 1),
			];
		});

	const [screeningName, setScreeningName] = React.useState(defaultScreeningName);
	const selectedScreening = React.useMemo(() => allScreenings.find(s => s.id === selectedScreeningId), [allScreenings, selectedScreeningId]);

	React.useEffect(() => {
		const fallbackName = allScreenings.length === 0 ? defaultScreeningName : '';
		setScreeningName(selectedScreening?.name ?? fallbackName);

		setSelectedTemplateCode(selectedScreening?.template ?? defaultTemplate);

		//setOpenedLines(selectedScreening?.visibleLines ?? []);
	}, [allScreenings.length, defaultScreeningName, defaultTemplate, selectedScreening]);

	// Changes the name on the screening itself
	const setScreeningName2 = (screeningId: string, name: string) =>
		setAllScreenings(current => {
			const index = current.findIndex(s => s.id === screeningId);
			if (index === -1) {
				return current;
			}

			return [
				...current.slice(0, index),
				{
					...current[index],
					name,
				},
				...current.slice(index + 1),
			];
		});

	const getFeeLineValueAndPrice = (templateLine: GetScreeningTemplates_screeningTemplates_lines, value: number, feeLine?: GetScreeningTemplates_screeningTemplates_lines) => {
		if (!feeLine || isNaN(value)) return;

		if (feeLine.linePremiumFixedAmount !== null) {
			const quantity = templateLine.type === ScreeningLineType.AMOUNT ? 1 : value;

			if (quantity !== 0) {
				return {
					value: 1,
					price: feeLine.linePremiumFixedAmount * quantity,
				};
			}
		} else if (feeLine.linePremiumPercentage !== null) {
			const lineQuantity = feeLine.type === ScreeningLineType.AMOUNT ? 1 : value;
			const linePrice = feeLine.type === ScreeningLineType.AMOUNT ? value : templateLine.price;
			const price = lineQuantity * linePrice;

			if (price !== 0) {
				return {
					value: 1,
					price: (feeLine.linePremiumPercentage / 100) * price,
				};
			}
		}
	};

	const createMappedFeeLine = (line: GetScreeningTemplates_screeningTemplates_lines, valueAndPrice: { value: number; price: number }, movableValues: MovableValues): MappedLine => {
		return {
			account: 'erpReferenceAccount' in line ? line.erpReferenceAccount : '',
			erpReferenceId: line.erpReferenceId,
			jobTask: 'erpReferenceTask' in line ? line.erpReferenceTask : '',
			type: ScreeningLineType.FEE,
			title: line.title,
			excludeFromEnvFee: line.excludeFromEnvFee,
			...valueAndPrice,
			...movableValues,
		};
	};

	const checkIfMovablesChanges = ({ movableDateFrom, movableDateTo, movableDays, movableVolume }: MovableValues): boolean => {
		if (movableDateFrom !== null) return true;
		if (movableDateTo !== null) return true;
		if (movableDays !== null) return true;
		if (movableVolume !== null) return true;

		return false;
	};

	// Changes/creates the value of an input
	const setScreeningLineValue = (
		screeningId: string,
		screeningLineId: string,
		value: number,
		movableValues: MovableValues = {
			movableDateFrom: null,
			movableDateTo: null,
			movableDays: null,
			movableVolume: null,
		},
	) =>
		setAllScreenings(current => {
			const index = current.findIndex(s => s.id === screeningId);
			if (index === -1) {
				return current;
			}

			const currentScreening = current[index];

			const templateLine = currentScreening.lines.find(r => r.erpReferenceId === screeningLineId);
			if (typeof templateLine === 'undefined') {
				return current;
			}

			const feeLine = currentScreening.lines.find(l => templateLine.linePremiumId !== null && l.id === templateLine.linePremiumId);
			const feeLineValueAndPrice = getFeeLineValueAndPrice(templateLine, value, feeLine);

			const mappedFeeLinesToAdd: MappedLine[] = [];

			const lineIndex = currentScreening.mappedLines.findIndex(ml => ml.erpReferenceId === screeningLineId);

			const line = currentScreening.mappedLines[lineIndex];

			if (lineIndex === -1) {
				if (feeLine && feeLineValueAndPrice) {
					// Add new fee line
					mappedFeeLinesToAdd.push(createMappedFeeLine(feeLine, feeLineValueAndPrice, movableValues));
				}

				//Creates new mappedLine
				return [
					...current.map(c => {
						if (c.id !== currentScreening.id) {
							return c;
						}

						return {
							...currentScreening,
							mappedLines: [
								...currentScreening.mappedLines,
								{
									account: templateLine.erpReferenceAccount,
									erpReferenceId: templateLine.erpReferenceId,
									jobTask: templateLine.erpReferenceTask,
									title: templateLine.title,
									type: templateLine.type,
									price: templateLine.type === ScreeningLineType.AMOUNT ? value : templateLine.price,
									value: templateLine.type === ScreeningLineType.AMOUNT ? 1 : value,
									excludeFromEnvFee: templateLine.excludeFromEnvFee,
									...movableValues,
								},
								...mappedFeeLinesToAdd,
							],
						};
					}),
				];
			}

			if (feeLine) {
				const currentFeeLineIndex = currentScreening.mappedLines.findIndex(line => line.erpReferenceId === feeLine.erpReferenceId);

				if (feeLineValueAndPrice) {
					if (currentFeeLineIndex === -1) {
						// Add new fee line
						mappedFeeLinesToAdd.push(createMappedFeeLine(feeLine, feeLineValueAndPrice, movableValues));
					} else {
						// Update existing fee line
						currentScreening.mappedLines[currentFeeLineIndex] = {
							...currentScreening.mappedLines[currentFeeLineIndex],
							...feeLineValueAndPrice,
						};
					}
				} else {
					// Delete fee line is there is no value/price for it
					currentScreening.mappedLines.splice(currentFeeLineIndex, 1);
				}
			}

			// Delete the template line if there is no value/price for it
			if (isNaN(value) || (value === 0 && !checkIfMovablesChanges(movableValues))) {
				currentScreening.mappedLines.splice(lineIndex, 1);
			}

			// Changes existing mapped line
			return [
				...current.map(c => {
					if (c.id !== currentScreening.id) {
						return c;
					}

					return {
						...currentScreening,
						mappedLines: [
							...currentScreening.mappedLines.map(l => {
								if (l.erpReferenceId !== line.erpReferenceId) {
									return l;
								}

								return {
									...l,
									price: templateLine.type === ScreeningLineType.AMOUNT ? value : templateLine.price,
									value: templateLine.type === ScreeningLineType.AMOUNT ? 1 : value,
									...movableValues,
								};
							}),
						],
					};
				}),
			];
		});

	// Changes/sets the custom tile on available inputs
	const setScreeningLineCustomLine = (
		screeningId: string,
		screeningLineId: string,
		customTitle: string,
		movableValues: MovableValues = {
			movableDateFrom: null,
			movableDateTo: null,
			movableDays: null,
			movableVolume: null,
		},
	) =>
		setAllScreenings(current => {
			const index = current.findIndex(s => s.id === screeningId);
			if (index === -1) {
				return current;
			}

			const lineIndex = current[index].mappedLines.findIndex(ml => ml.erpReferenceId === screeningLineId);
			const templateLine = current[index].lines.find(r => r.erpReferenceId === screeningLineId);
			if (typeof templateLine === 'undefined') {
				return current;
			}
			if (lineIndex === -1) {
				return [
					...current.slice(0, index),
					{
						...current[index],
						mappedLines: [
							...current[index].mappedLines,
							{
								account: templateLine.erpReferenceAccount,
								erpReferenceId: templateLine.erpReferenceId,
								jobTask: templateLine.erpReferenceTask,
								type: templateLine.type,
								title: customTitle.length > 0 ? customTitle : templateLine.title,
								price: templateLine.type === ScreeningLineType.AMOUNT ? 0 : templateLine.price,
								value: templateLine.type === ScreeningLineType.AMOUNT ? 1 : 0,
								excludeFromEnvFee: templateLine.excludeFromEnvFee,
								...movableValues,
							},
						],
					},
					...current.slice(index + 1),
				];
			}

			return [
				...current.slice(0, index),
				{
					...current[index],
					mappedLines: [
						...current[index].mappedLines.slice(0, lineIndex),
						{
							...current[index].mappedLines[lineIndex],
							title: customTitle.length > 0 ? customTitle : templateLine.title,
						},
						...current[index].mappedLines.slice(lineIndex + 1),
					],
				},
				...current.slice(index + 1),
			];
		});

	// Use this to keep track of the expanded calenders
	const [expandedCalenders, toggleExpandedCalenders] = useToggleSet<string>();

	// Setup create and update mutations
	const [createScreening, createResult] = useMutation<CreateScreening, CreateScreeningVariables>(CREATE_SCREENING);
	const [updateScreening, updateResult] = useMutation<UpdateScreening, UpdateScreeningVariables>(UPDATE_SCREENING);
	const [unlockScreening, unlockResult] = useMutation<UnlockScreening, UnlockScreeningVariables>(UNLOCK_SCREENING);
	const [deleteScreening, deleteResult] = useMutation<DeleteScreening, DeleteScreeningVariables>(DELETE_SCREENING);
	const submitting = createResult.loading || updateResult.loading || deleteResult.loading;

	// Setup get template query
	const [fetchTemplate, { data, called: templateFetched, loading: loadingTemplate }] = useLazyQuery<GetScreeningTemplates>(GET_SCREENING_TEMPLATES);

	// Fetch the template if it hasn't been fetched and the modal is visible
	React.useEffect(() => {
		if (visible && !templateFetched) {
			fetchTemplate();
		}
	}, [visible, templateFetched, fetchTemplate]);

	// Fetch screening position help texts when template changes
	const positions = React.useMemo(
		() => (data?.screeningTemplates.find(st => st.templateCode === selectedTemplateCode)?.lines ?? []).map(l => l.position).filter((p): p is string => typeof p === 'string' && p.length > 0),
		[data, selectedTemplateCode],
	);

	const { data: positionTextsData } = useQuery<GetWebScreeningPositionTexts, GetWebScreeningPositionTextsVariables>(GET_SCREENING_POSITION_TEXTS, {
		variables: {
			positions,
		},
		skip: positions.length === 0,
	});

	// Extract, sort, and map the lines
	React.useEffect(() => {
		if (typeof data !== 'undefined') {
			const screeningsWithTemplates: AllScreeningsType[] = screenings.map(s => {
				const template = data.screeningTemplates.find(st => st.templateCode === s.template);

				const { lines, rootLines, mappedLines } =
					typeof template !== 'undefined'
						? getScreeningLines(template, s.data, t)
						: {
							lines: [] as TemplateLine[],
							rootLines: [] as Heading[],
							mappedLines: [] as MappedLine[],
						};
				return {
					...s,
					lines,
					rootLines,
					mappedLines,
				};
			});
			setAllScreenings(screeningsWithTemplates);
		}
		// Find template and get screening lines
	}, [data, screenings, t]);

	// Setup the screening price calculation
	//const screeningPriceCalculation = calculateEnvironmentalFeeForLines(selectedScreening?.mappedLines, debitor);

	const allScreeningsPriceCalculation = allScreenings.reduce((sum, screening) => {
		const sumFeeResult = calculateEnvironmentalFeeForLines(screening.mappedLines, debitor);
		return sum + sumFeeResult.sum + 0;
	}, 0);

	const screeningPriceCalculationNoFee = selectedScreening?.mappedLines.reduce((sum, line) => sum + (isNaN(line.price) ? 0 : line.price) * (isNaN(line.value) ? 0 : line.value), 0) ?? 0;

	// const allScreeningsPriceCalculationNoFee = allScreenings.reduce(
	//     (sum, screening) =>
	//     sum + screening.mappedLines.reduce((sum, line) => sum + (isNaN(line.price) ? 0 : line.price) * (isNaN(line.value) ? 0 : line.value), 0) ?? 0,
	//     0,
	//     );
	const screeningPriceFee = React.useMemo(() => calculateEnvironmentalFeeForLines(allScreenings.flatMap(l => l.mappedLines), debitor), [allScreenings, debitor]);

	const onSubmit = async () => {
		try {
			const allscreeningsUpdated: AllScreeningsType[] = [];

			if (enabledEnvironmentalFeeFlag) {
				let totalEnvFee = 0;
				const debitorMax = debitor.maxEnvironmentFeeAmount;
				const enforceMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);
				allScreenings.forEach(function (item, i) {
					let sumFeeResultFee = calculateEnvironmentalFeeForLines(item.mappedLines, debitor).fee;
					if ((enforceMaxEnvFeeAmount && (totalEnvFee + sumFeeResultFee) >= debitorMax)) {
						sumFeeResultFee = debitorMax - totalEnvFee;
					}
					totalEnvFee += sumFeeResultFee;
					const envLineExists = item.mappedLines.filter(x => x.type === ScreeningLineType.RESOURCE);
					if (envLineExists && envLineExists.length > 0) {
						item.mappedLines.filter(x => x.type === ScreeningLineType.RESOURCE)[0].price = sumFeeResultFee;
					} else {
						const line: MappedLine = {
							type: ScreeningLineType.RESOURCE,
							price: sumFeeResultFee,
							erpReferenceId: item.lines[0].erpReferenceId,
							title: DEFAULT_ENVFEE_NAME,
							account: '',
							jobTask: '',
							value: 1,
							movableVolume: null,
							movableDays: null,
							movableDateFrom: null,
							movableDateTo: null,
							excludeFromEnvFee: false,
						};

						item.mappedLines.push(line);
					}

					allscreeningsUpdated.push(item);
				});
			}

			await Promise.all([
				// Delete all deleted screenings
				...deletedScreeningsIdList
					.filter(deletedId => !deletedId.startsWith(CREATE_SCREENING_PREFIX))
					.map(deletedId =>
						deleteScreening({
							variables: {
								caseId,
								screeningERPReferenceNo: deletedId,
							},
						}).then(() => undefined),
					),

				// Update all existing screenings and create new ones
				...allScreenings
					.filter(screening => !screening.locked)
					.map(screening =>
						!screening.id.startsWith(CREATE_SCREENING_PREFIX)
							? updateScreening({
								variables: {
									caseId,
									screeningERPReferenceNo: screening.id,
									name: screening.name,
									lines: screening.mappedLines,
								},
							}).then(() => undefined)
							: createScreening({
								variables: {
									caseId,
									template: screening.template,
									name: screening.name,
									lines: screening.mappedLines,
								},
							}).then(() => undefined),
					),
			]);
		} finally {
			// Wrap in try/finally so that we perform the submitCb() regardless of errors.
			// In the case of an error, the submitCb() function will refetch the latest data..
			// ..meaning the user will not have the opportunity to create duplicates.
			setShowPredictionModal(false);
			submitCb();
		}
	};

	const [screeningUnlocked, setScreeningUnlocked] = React.useState<string[]>([]);

	const onUnlock = async () => {
		if (typeof selectedScreening !== 'undefined') {
			if (window.confirm(t('case.screeningUnlockWarning'))) {
				await unlockScreening({
					variables: {
						caseId,
						screeningERPReferenceNo: selectedScreening.erpReferenceNo,
					},
				});
				setScreeningUnlocked(current => [...current, selectedScreening.erpReferenceNo]);
				submitUnlockCb?.();
			}
		}
	};



	const allRootIds = selectedScreening?.rootLines.map(r => r.id) ?? [];

	const noTemplate = (loadingTemplate === false && data == null) || selectedTemplateCode === NO_SCREENING_TEMPLATE;
	const isBlockedName =
		screeningName.toLocaleUpperCase() !== selectedScreening?.name?.toLocaleUpperCase() && allScreeningNames.some(sn => sn.toLocaleUpperCase() === screeningName.toLocaleUpperCase());

	const [showPredictionModal, setShowPredictionModal] = React.useState(false);
	const [suggestionIds, setSuggestionIds] = React.useState<string[]>([]);
	return (
		<>
			<Modal
				visible={visible}
				title="case.screening"
				close={close}
				size={ModalSize.XLARGE}
				body={
					<Tabs
						pushLast
						titles={[...allScreeningNames, t('common.createNew')]}
						icons={[...allScreeningNames.map(name => (allScreenings.find(s => s.name === name)?.locked ? faLock : null)), faPlus]}
						active={selectedScreening?.name ?? t('common.createNew')}
						onClick={name => {
							const selectedId = allScreenings.find(s => s.name === name)?.id;
							setSelectedScreeningId(selectedId);
						}}
						className="h-3/4 px-3"
					>
						<div key={selectedScreeningId} className="mb-6 flex flex-row items-stretch pb-6">
							<div className="w-2/6">
								<Input
									name="screeningName"
									type="text"
									title="common.name"
									errorMessage={isBlockedName && !selectedScreening?.locked ? 'case.screeningBlockedName' : undefined}
									value={selectedScreening?.name}
									onChange={e => {
										setScreeningName(e.currentTarget.value);
										if (typeof selectedScreeningId !== 'undefined') {
											setScreeningName2(selectedScreeningId, e.currentTarget.value);
										}
									}}
									readOnly={selectedScreening?.locked}
									required
								/>
							</div>

							<div className="w-1/6 self-end py-2">
								{editMode && !selectedScreening?.locked && (
									<TextButton
										icon={faTrash}
										text="case.screeningDelete"
										onClick={async () => {
											if (typeof selectedScreeningId !== 'undefined' && window.confirm(t('case.screeningDeleteConfirmation'))) {
												setDeletedScreeningsIdList(current => [...current, selectedScreeningId]);
											}
										}}
										disabled={submitting}
									/>
								)}
							</div>

							<div className="w-2/6">
								<Dropdown
									name="selectedTemplateCode"
									title="case.screeningTemplate"
									value={selectedTemplateCode}
									data={[
										{ label: '', value: NO_SCREENING_TEMPLATE },
										...(data?.screeningTemplates ?? []).map(st => ({
											label: st.templateName,
											value: st.templateCode,
										})),
									]}
									onChange={e => setSelectedTemplateCode(e.target.value)}
									disabled={editMode || selectedScreening?.locked}
									loading={loadingTemplate}
								/>
							</div>

							<div className="w-1/6">
								{editMode && !noTemplate && (
									<>
										<TextButton
											text="case.screeningSelectAll"
											className="text-blue"
											icon={faPlusSquare}
											onClick={() => selectedScreeningId && toggleAllLines(selectedScreeningId, allRootIds)}
										/>
										<TextButton
											text="case.screeningDeselectAll"
											className="text-blue"
											icon={faMinusSquare}
											onClick={() => selectedScreeningId && toggleAllLines(selectedScreeningId, [])}
										/>
									</>
								)}
							</div>
						</div>
						{!editMode ? (
							<Button
								success
								text="case.screeningCreate"
								onClick={() => {
									// Find template and get screening lines
									const template = data?.screeningTemplates.find(st => st.templateCode === selectedTemplateCode);
									if (typeof template === 'undefined') {
										return;
									}

									const { lines, rootLines, mappedLines } = getScreeningLines(template, {}, t);
									const id = CREATE_SCREENING_PREFIX + String(Date.now());
									setAllScreenings(allS => [
										...allS,
										{
											id,
											name: screeningName,
											template: selectedTemplateCode,
											templateId: template.erpReferenceId ?? '',
											templateName: template.templateName,
											locked: false,
											reopenAllowed: true,
											data: {},
											lines,
											rootLines,
											mappedLines,
											visibleLines: [],
											erpReferenceNo: '?',
										},
									]);
									setSelectedScreeningId(id);
								}}
								disabled={screeningName.length === 0 || noTemplate || isBlockedName}
							/>
						) : (
							<>
								{typeof selectedScreening === 'undefined' || noTemplate ? (
									<p>{t('case.screeningNoTemplate')}</p>
								) : (
									<form
										style={{
											height: 'calc(100vh - 4rem - 233px)',
										}}
										className="overflow-x-hidden overflow-y-scroll"
									>
										{' '}
										{/* 4rem is modal padding, 233px is modal header and footer */}
										<div className="-mx-8 flex flex-wrap">
											{selectedScreening.rootLines.map(rootLine => {
												const headingError = validateRootHeading(rootLine, selectedScreening.mappedLines);

												function mapLine(line: Heading): React.ReactElement {
													const key = String(line.id);
													const mappedLine: MappedLine | undefined = selectedScreening?.mappedLines.find(ml => ml.erpReferenceId === line.erpReferenceId);
													const lineOpened = selectedScreening?.visibleLines.includes(line.id);
													const feeLine = data?.screeningTemplates
														.find(st => st.templateCode === selectedTemplateCode)
														?.lines.find(l => line.linePremiumId !== null && l.id === line.linePremiumId);
													let feeInfo: React.ReactElement | null = null;
													if (typeof feeLine?.linePremiumFixedAmount === 'number') {
														feeInfo = (
															<small className="w-50 flex flex-row justify-between px-1">
																<span>
																	{feeLine.title}
																	:&nbsp;
																</span>
																<span>
																	{((mappedLine?.value ?? 0) * feeLine.linePremiumFixedAmount).toLocaleString(undefined, {
																		maximumFractionDigits: 2,
																	})}
																</span>
															</small>
														);
													} else if (typeof feeLine?.linePremiumPercentage === 'number') {
														feeInfo = (
															<small className="w-50 flex flex-row justify-between px-1">
																<span>
																	{feeLine.title}
																	:&nbsp;
																</span>
																<span>
																	{((mappedLine?.value ?? 0) * (mappedLine?.price ?? 0) * (feeLine.linePremiumPercentage / 100)).toLocaleString(undefined, {
																		maximumFractionDigits: 2,
																	})}
																</span>
															</small>
														);
													}

													const positionText = positionTextsData?.screeningPositionTexts.find(spt => spt.position === line.position)?.text;

													const customTitleInput = line.allowCustomTitle ? (
														<Input
															compact
															name={`${key}-customTitle`}
															type="text"
															maxLength={99} // BC limit is 100 charaters
															placeholder={t('common.description')}
															readOnly={selectedScreening?.locked}
															value={mappedLine?.title !== line.title ? mappedLine?.title : undefined}
															onChange={e => {
																selectedScreeningId && setScreeningLineCustomLine(selectedScreeningId, line.erpReferenceId, e.currentTarget.value);
															}}
														/>
													) : null;

													const isRadioHeader = line.type === ScreeningLineType.HEADING && line.subLines.some(l => l.type === ScreeningLineType.RADIO);
													if (isRadioHeader) {
														return (
															<Fragment key={key + selectedScreeningId}>
																<div
																	className="flex w-full cursor-pointer flex-row justify-between py-2"
																	onClick={() => selectedScreeningId && openedLinesHandler(selectedScreeningId, line.id)}
																>
																	<div className="mr-2">
																		<FontAwesomeIcon icon={lineOpened ? faMinus : faPlus} className="text-blue" />
																	</div>
																	<div className="w-full">
																		<FormFieldHeader
																			smallTitle
																			title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
																			titleClass={classNames({
																				truncate: !lineOpened,
																			})}
																			labelClass="cursor-pointer"
																			required={line.required}
																		/>
																	</div>
																</div>
																<div
																	className={classNames({
																		hidden: !lineOpened,
																	})}
																>
																	<Radio
																		name={key}
																		title=""
																		className="text-sm"
																		options={line.subLines
																			.filter(l => l.type === ScreeningLineType.RADIO)
																			.map(l => {
																				const positionText = positionTextsData?.screeningPositionTexts.find(spt => spt.position === l.position)?.text;
																				return {
																					label: l.position ? `${l.title} (${l.position})` : `${l.title}`,
																					labelInfoPopover: typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined,
																					value: String(l.id),
																					children: mappedLine?.value !== l.id ? null : l.subLines.map(mapLine),
																				};
																			})}
																		onChange={e => {
																			if (typeof selectedScreeningId !== 'undefined') {
																				setScreeningLineValue(selectedScreeningId, line.erpReferenceId, Number(e.currentTarget.value));
																			}
																		}}
																		labelWeight="NONE"
																		disabled={selectedScreening?.locked}
																	/>

																	{customTitleInput}
																	{feeInfo}

																	{line.subLines.filter(l => l.type !== ScreeningLineType.RADIO).map(mapLine)}
																</div>
															</Fragment>
														);
													}

													const isBooleanHeader = line.type === ScreeningLineType.BOOLEAN && line.subLines.length > 0;
													if (isBooleanHeader) {
														return (
															<Fragment key={key + selectedScreeningId}>
																<DynamicInput
																	name={key}
																	type="BOOLEAN"
																	unit={line.uom}
																	title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
																	labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
																	disabled={selectedScreening?.locked}
																	value={mappedLine?.value}
																	onChange={e => {
																		if (typeof selectedScreeningId !== 'undefined') {
																			setScreeningLineValue(selectedScreeningId, line.erpReferenceId, e.currentTarget.checked ? 1 : 0);
																		}
																	}}
																/>
																{Boolean(mappedLine?.value) && line.subLines.map(mapLine)}
																{customTitleInput}
																{feeInfo}
															</Fragment>
														);
													}

													const isHeader = line.type === ScreeningLineType.HEADING;
													if (isHeader) {
														return (
															<Fragment key={key + selectedScreeningId}>
																<div
																	className="flex w-full cursor-pointer flex-row justify-between py-2"
																	onClick={() => selectedScreeningId && openedLinesHandler(selectedScreeningId, line.id)}
																>
																	<div className="mr-2">
																		<FontAwesomeIcon icon={lineOpened ? faMinus : faPlus} className="text-blue" />
																	</div>
																	<div className="w-full">
																		<FormFieldHeader
																			smallTitle
																			title={line.title}
																			titleClass={classNames({
																				truncate: !lineOpened,
																			})}
																			labelClass="cursor-pointer"
																			required={line.required}
																		/>
																	</div>
																</div>
																<div
																	className={classNames({
																		hidden: !lineOpened,
																	})}
																>
																	{line.subLines.map(mapLine)}
																</div>
															</Fragment>
														);
													}

													const isDateRange = line.type === ScreeningLineType.INTEGER && line.showCalendar;
													if (isDateRange) {
														return (
															<Fragment key={key + selectedScreeningId}>
																<Input
																	compact
																	name={`${key}-movableDays`}
																	type="number"
																	title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
																	labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
																	unit={line.uom}
																	value={mappedLine?.movableDays ?? undefined}
																	readOnly={selectedScreening?.locked}
																	onChange={e => {
																		selectedScreeningId &&
																			setScreeningLineValue(
																				selectedScreeningId,
																				line.erpReferenceId,
																				e.currentTarget.valueAsNumber * (mappedLine?.movableVolume ?? 0),
																				{
																					movableDateFrom: mappedLine?.movableDateFrom ?? null,
																					movableDateTo: mappedLine?.movableDateTo ?? null,
																					movableDays: e.currentTarget.valueAsNumber ?? null,
																					movableVolume: mappedLine?.movableVolume ?? null,
																				},
																			);
																	}}
																	header={
																		<>
																			<p className="text-blue block cursor-pointer text-sm font-semibold" onClick={() => toggleExpandedCalenders(key)}>
																				{t('case.screeningShowCalender')}
																			</p>

																			<DateRangeInput
																				fromValue={mappedLine?.movableDateFrom}
																				toValue={mappedLine?.movableDateTo}
																				onChange={(from, to, count) => {
																					selectedScreeningId &&
																						setScreeningLineValue(selectedScreeningId, line.erpReferenceId, (mappedLine?.movableVolume ?? 0) * count, {
																							movableDateFrom: formatDateForInput(from),
																							movableDateTo: formatDateForInput(to),
																							movableDays: count,
																							movableVolume: mappedLine?.movableVolume ?? null,
																						});
																				}}
																				className={classNames('mb-2 mt-1', {
																					hidden: !expandedCalenders.has(key),
																				})}
																			/>
																		</>
																	}
																/>
																<Input
																	compact
																	name={`${key}-movableVolume`}
																	type="number"
																	unit={t('movable.volumeUnit')}
																	readOnly={selectedScreening?.locked}
																	value={mappedLine?.movableVolume ?? undefined}
																	onChange={e => {
																		selectedScreeningId &&
																			setScreeningLineValue(
																				selectedScreeningId,
																				line.erpReferenceId,
																				e.currentTarget.valueAsNumber * (mappedLine?.movableDays ?? 0),
																				{
																					movableDateFrom: mappedLine?.movableDateFrom ?? null,
																					movableDateTo: mappedLine?.movableDateTo ?? null,
																					movableDays: mappedLine?.movableDays ?? null,
																					movableVolume: e.currentTarget.valueAsNumber,
																				},
																			);
																	}}
																/>
																<small className="flex w-full flex-row justify-between px-1 lg:w-3/4">
																	<span className="mx-1">{t('common.total')}</span>
																	<span className="flex-grow-default text-right">
																		{((mappedLine?.movableDays ?? 0) * (mappedLine?.movableVolume ?? 0)).toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																		})}
																	</span>
																	<span className="mx-1">{t('movable.volumeUnit')}</span>
																</small>

																{customTitleInput}
																{feeInfo}
															</Fragment>
														);
													}
													return (
														<Fragment key={key + selectedScreeningId}>
															<DynamicInput
																name={key}
																type={line.type === ScreeningLineType.BOOLEAN ? 'BOOLEAN' : 'NUMBER'}
																unit={line.uom}
																title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
																labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
																labelWeight="NONE"
																disabled={selectedScreening?.locked}
																value={line.type !== ScreeningLineType.AMOUNT ? mappedLine?.value : mappedLine?.price}
																onChange={e => {
																	if (typeof selectedScreeningId !== 'undefined') {
																		line.type !== ScreeningLineType.BOOLEAN &&
																			setScreeningLineValue(selectedScreeningId, line.erpReferenceId, e.currentTarget.valueAsNumber);
																		line.type === ScreeningLineType.BOOLEAN &&
																			setScreeningLineValue(selectedScreeningId, line.erpReferenceId, e.target.checked ? 1 : 0);
																	}
																}}
															/>
															{customTitleInput}
															{feeInfo}
														</Fragment>
													);
												}
												const rootLineOpened = selectedScreening.visibleLines.includes(rootLine.id);
												return (
													<div key={rootLine.id + (selectedScreeningId ?? '')} className="w-1/4 px-8">
														{rootLine.type !== ScreeningLineType.HEADING ? (
															mapLine(rootLine)
														) : (
															<div className="mb-4">
																<div
																	className="flex w-full cursor-pointer flex-row justify-between py-2"
																	onClick={() => selectedScreeningId && openedLinesHandler(selectedScreeningId, rootLine.id)}
																>
																	<div className="mr-2">
																		<FontAwesomeIcon icon={rootLineOpened ? faMinus : faPlus} className="text-blue" />
																	</div>
																	<div className="w-full">
																		<FormFieldHeader
																			title={rootLine.title}
																			titleClass={classNames({
																				truncate: !rootLineOpened,
																			})}
																			labelClass="cursor-pointer"
																			labelWeight="BOLD"
																			required={rootLine.required}
																		/>
																	</div>
																</div>

																{headingError != null && <FormErrorText text={t(headingError)} />}

																<div
																	className={classNames({
																		'mb-4': rootLineOpened,
																		hidden: !rootLineOpened,
																	})}
																>
																	{rootLine.subLines.map(mapLine)}
																</div>
															</div>
														)}
													</div>
												);
											})}
										</div>
									</form>
								)}
							</>
						)}
					</Tabs>
				}
				footer={
					<div className="mt-3 flex w-full flex-row items-center justify-between">
						<div className="flex items-center space-x-4">
							<Button
								success
								text="common.save"
								onClick={() => aIInvoicePredictionsScreeningFlag ? setShowPredictionModal(true) : onSubmit()}
								disabled={submitting || (selectedScreening?.rootLines.some(rl => validateRootHeading(rl, selectedScreening.mappedLines) != null) ?? false) || screeningName.length === 0 || isBlockedName}
								loading={submitting}
							/>

							{typeof selectedScreening !== 'undefined' && selectedScreening.locked && selectedScreening.reopenAllowed && unlockScreeningFlag && (
								<Button
									secondary
									text="case.screeningUnlock"
									onClick={() => onUnlock()}
									icon={faLockOpen}
									disabled={submitting || (selectedScreening.rootLines.some(rl => validateRootHeading(rl, selectedScreening.mappedLines) != null) ?? false) || unlockResult.loading}
									loading={unlockResult.loading}
								/>
							)}
							{typeof selectedScreening !== 'undefined' && screeningUnlocked.includes(selectedScreening.erpReferenceNo) && (
								<div className="bg-blue border-blue border-1 rounded-md bg-opacity-25 p-2">
									<p className="text-sm">
										<FontAwesomeIcon icon={faExclamationCircle} className="text-blue mr-3" />
										{t('case.saveScreeningReminder')}
									</p>
								</div>
							)}
						</div>

						{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
							<div className="mt-2 text-left">
								<p>
									{t('case.invoice.envFee')}: {debitor.environmentFeePercentage}%&nbsp;
									{t('case.invoice.maxEnvFee')}: {hasMaxEnvFeeAmount ? debitor.maxEnvironmentFeeAmount : 'N/A'}
								</p>
								{/* <p>
                                {t('case.invoice.envFeeTotal')}: {screeningPriceCalculation.fee}
                            </p> */}
							</div>
						)}

						<div>
							<div className="flex justify-between">
								{t('case.screeningTotal')}
								<strong className="px-3">{screeningPriceCalculationNoFee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>
							</div>
							{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
								<div className="flex justify-between">
									{t('case.envFeeScreening')}
									<strong className="px-3">{screeningPriceFee.fee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>
								</div>
							)}

							<div className="flex justify-between">
								{t('case.screeningTotalAll')}
								<strong className="px-3">
									{(allScreeningsPriceCalculation + screeningPriceFee.fee).toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}
								</strong>
							</div>
						</div>
					</div>
				}
			/>
			{showPredictionModal &&
				<Modal
					visible={showPredictionModal}
					title="case.screeningSuggestions"
					close={() => setShowPredictionModal(false)}
					size={ModalSize.MEDIUM}
					body={
						<>
							<ScreeningSuggestions caseId={caseId} screenings={allScreenings} positionTextsData={positionTextsData} data={data} setScreeningLineCustomLine={setScreeningLineCustomLine} setScreeningLineValue={setScreeningLineValue} />
						</>
					}
					footer={
						<div className="mt-3 flex w-full flex-row items-center justify-between">
							<div className="flex items-center space-x-4">
								<Button
									success
									text="common.save"
									onClick={onSubmit}
									disabled={submitting || (selectedScreening?.rootLines.some(rl => validateRootHeading(rl, selectedScreening.mappedLines) != null) ?? false)}
									loading={submitting}
								/>
								{/* {aIInvoicePredictionsScreeningFlag && (
									<Button
										success
										text="Test AI predictions"
										onClick={() => setShowPredictionModal(true)}
									/>
								)}
								{typeof selectedScreening !== 'undefined' && selectedScreening.locked && selectedScreening.reopenAllowed && unlockScreeningFlag && (
									<Button
										secondary
										text="case.screeningUnlock"
										onClick={() => onUnlock()}
										icon={faLockOpen}
										disabled={submitting || (selectedScreening.rootLines.some(rl => validateRootHeading(rl, selectedScreening.mappedLines) != null) ?? false) || unlockResult.loading}
										loading={unlockResult.loading}
									/>
								)}
								{typeof selectedScreening !== 'undefined' && screeningUnlocked.includes(selectedScreening.erpReferenceNo) && (
									<div className="bg-blue border-blue border-1 rounded-md bg-opacity-25 p-2">
										<p className="text-sm">
											<FontAwesomeIcon icon={faExclamationCircle} className="text-blue mr-3" />
											{t('case.saveScreeningReminder')}
										</p>
									</div>
								)} */}
							</div>

							{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
								<div className="mt-2 text-left">
									<p>
										<p>{t('case.invoice.envFee')}: {debitor.environmentFeePercentage}%&nbsp;</p>
										<p>{t('case.invoice.maxEnvFee')}: {hasMaxEnvFeeAmount ? debitor.maxEnvironmentFeeAmount : 'N/A'}</p>
									</p>
									{/* <p>
									{t('case.invoice.envFeeTotal')}: {screeningPriceCalculation.fee}
								</p> */}
								</div>
							)}

							<div>
								{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
									<div className="flex justify-between">
										{t('case.envFeeScreening')}
										<strong className="px-3">{screeningPriceFee.fee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>
									</div>
								)}

								<div className="flex justify-between">
									{t('case.screeningTotalAll')}
									<strong className="px-3">
										{(allScreeningsPriceCalculation + screeningPriceFee.fee).toLocaleString(undefined, {
											maximumFractionDigits: 2,
										})}
									</strong>
								</div>
							</div>
						</div>
					}
				/>
			}
		</>
	);
};

export default ScreeningModal;
