import React from 'react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { useQuery } from '@apollo/client';
import { GetUserFlexTime, GetUserFlexTimeVariables } from '../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router-dom';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { Permissions } from '@ssg/common/GraphQL';
import classNames from 'classnames';
import Header from '@ssg/common/Components/Header';
import UserContext from '../../UserContext';
import TimeRegistrationOverview from './TimeRegistrationOverview';
import OpenRegistrationsOverview from './OpenRegistrationsOverview';
import AllRegistrationsOverview from './AllRegistrationsOverview';
import RejectedRegistrationsOverview from './RejectedRegistrationsOverview';
import TimeRegistrationOverviewNew from '@ssg/common/TimeRegistration/TimeRegistrationOverviewNew';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import AllRegistrationsOverviewNew from './AllRegistrationsOverviewNew';
import RejectedRegistrationsOverviewNew from './RejectedRegistrationsOverviewNew';
import OpenRegistrationsOverviewNew from './OpenRegistrationsOverviewNew';
import RejectedTimeRegistrationWarningModal from './RejectedTimeRegistrationModalNew';

const GET_USER_FLEX_TIME = loader('src/GraphQL/Users/GetUserFlexTime.gql');

const TimeRegistrationTabs: React.FC = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const flexEnabled = useFlag(FeatureFlagEnums.FLEX_TIME);
	const rejectedTimeRegistrationWarningFlag = useFlag(FeatureFlagEnums.REJECTED_TIME_REGISTRATION_WARNING);
	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);
	const deleteInvoicedTimeRegs = useFlag(FeatureFlagEnums.DELETE_INVOICED_TIMEREGS);
	
	const showApproveTab = arraysHasMatchingValue(userContext.user?.permissions as unknown as Permissions[] ?? [], [Permissions.TIME_TRACKING_APPROVER, Permissions.TIME_TRACKING_RESPONSIBLE]);
	
	const [tabs, setTabs] = React.useState<string[]>([]);
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	const { data: flexTime } = useQuery<GetUserFlexTime, GetUserFlexTimeVariables>(GET_USER_FLEX_TIME, {
		variables: {
			user: userContext.user?.id ?? '',
		},
		fetchPolicy: 'no-cache',
		skip: !flexEnabled || userContext.user?.external,
	});

	React.useEffect(() => {
		if (userContext) {
			if(newTimeregistration) {
				setTabs(['timeRegistration.overview', 'timeRegistration.reviewTimeRegNew', 'timeRegistration.rejectedTimeRegistrations', 'timeRegistration.reviewTimeReg']);
			} else {
				setTabs(['timeRegistration.overview', 'timeRegistration.reviewTimeReg', 'timeRegistration.rejectedTimeRegistrations']);
			}
			if (showApproveTab && newTimeregistration) {
				setTabs(prevState => [...prevState, 'timeRegistration.reviewDayNotClosed']);
			} else if (showApproveTab && !newTimeregistration) {
				setTabs(prevState => [...prevState, 'timeRegistration.reviewDayNotClosed']);
			}

			if(deleteInvoicedTimeRegs) {
				setTabs(prevState => [...prevState, 'timeRegistration.overviewOld']);
			}
		}
	}, [deleteInvoicedTimeRegs, newTimeregistration, showApproveTab, userContext]);

	React.useEffect(() => {
		if (tabs.length) {
			setActiveTab(tabs[0]);
		}
		if (location.search === '?=rejectedhours' && rejectedTimeRegistrationWarningFlag) {
			// query param used when navigating from mobile app.
			setActiveTab(tabs[2]);
		}
	}, [tabs, location, rejectedTimeRegistrationWarningFlag]);

	const timeregPermissions = {
		approver: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_APPROVER),
		responsible: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_RESPONSIBLE),
		datePicker: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER),
	};

	return (
		<div>
			<Header title="timeRegistration.overviewTitle">
				<div className="flex justify-between">
					<div className="flex">
						{tabs.map((title) => {
							return (
								<div key={title} className="mr-3 mt-1">
									<button
										type="button"
										onClick={() => setActiveTab(title)}
										className={classNames('text-blue focus:outline-none', {
											'border-blue border-b-2': title === activeTab,
										})}
									>
										{t(title)}
									</button>
								</div>
							);
						})}
					</div>
					{flexEnabled &&
						<div className="mr-3 mt-1">
							<p className="text-blue font-bold">
								{' '}
								<FontAwesomeIcon icon={faClock} />{' '}
								{flexTime?.userFlexTime ? `${t('timeRegistration.flexTime')}: ${flexTime?.userFlexTime} ${t('common.hours')}` : t('timeRegistration.noFlexSetup')}
							</p>
						</div>
					}
				</div>
			</Header>

			<div>
				{(newTimeregistration && activeTab === 'timeRegistration.overview') &&
					<TimeRegistrationOverviewNew
						userContext={{
							id: userContext.user?.id ?? '',
							permissions: timeregPermissions,
							salaryType: userContext.user?.employeeSalaryType ?? null,
							hireDate: userContext.user?.employeeHireStartDate ?? null,
						}}
						rejectModal={<RejectedTimeRegistrationWarningModal userId={userContext.user?.id} />}
					/>
				}
				{(newTimeregistration && activeTab === 'timeRegistration.reviewTimeRegNew') && <AllRegistrationsOverviewNew />}
				{(newTimeregistration && activeTab === 'timeRegistration.rejectedTimeRegistrations') && <RejectedRegistrationsOverviewNew />}
				{(newTimeregistration && activeTab === 'timeRegistration.reviewDayNotClosed') && <OpenRegistrationsOverviewNew />}
				{(!newTimeregistration && activeTab === 'timeRegistration.overview') && <TimeRegistrationOverview />}
				{(deleteInvoicedTimeRegs && activeTab === 'timeRegistration.overviewOld') && <TimeRegistrationOverview />}
				{(!newTimeregistration &&activeTab === 'timeRegistration.reviewDayNotClosed') && <OpenRegistrationsOverview />}
				{activeTab === 'timeRegistration.reviewTimeReg' && <AllRegistrationsOverview />}
				{(!newTimeregistration &&activeTab === 'timeRegistration.rejectedTimeRegistrations') && <RejectedRegistrationsOverview />}
			</div>
		</div>
	);
};

export default TimeRegistrationTabs;
