import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetPaymentSupplements, GetTimeTrackings, GetTimeTrackingsVariables } from '../../GraphQL';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import AllRegsOverviewFilter from './AllRegsOverviewFilter';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import UserContext from '../../UserContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_TIME_TRACKINGS = loader('src/GraphQL/TimeTracking/GetTimeTrackings.gql');
const GET_PAYMENT_SUPPLEMENTS = loader('src/GraphQL/TimeTracking/GetPaymentSupplements.gql');

export interface TrackingFilters {
	minDate: string;
	maxDate: string;
	view: 'OWN' | 'ALL';
}
interface TimeRegOverview {
	id: string;
	date: string;
	user: {
		name: string;
	};
	case: {
		erpNo: string;
		debitor: {
			company: string;
		};
		damage: {
			contact: {
				address: {
					postalCode: string;
					city: string;
					road: string;
					houseNumber: string;
				};
			};
			category: {
				name: string;
			};
			cause: {
				name: string;
			};
		};
	} | null;
	jobTaskNo: string;
	jobTaskName: string;
	workTypeCode: string;
	onCall: boolean;
	remark: string | null;
	hours: number;
	addonLines:
		| {
				paymentSupplementCode: string;
				paymentSupplementName: string;
				hours: number;
				onCall: boolean;
		  }[]
		| undefined
		| null;
}

const AllRegistrationsOverview: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const lastMonth = new Date();
	lastMonth.setDate(lastMonth.getDate() - 30);

	const [activeFilters, setActiveFilters] = React.useState<TrackingFilters>({
		minDate: formatDateForInput(lastMonth),
		maxDate: formatDateForInput(new Date()),
		view: 'OWN',
	});

	const { data: timeTrackingData, loading: loadingTimeTracking } = useQuery<GetTimeTrackings, GetTimeTrackingsVariables>(GET_TIME_TRACKINGS, {
		variables: {
			minDate: activeFilters.minDate,
			maxDate: activeFilters.maxDate,
			users: activeFilters.view === 'OWN' ? [userContext.user?.id ?? 'error'] : undefined,
		},
		fetchPolicy: 'cache-and-network',
	});

	const { data: paymentSupplementsData } = useQuery<GetPaymentSupplements>(GET_PAYMENT_SUPPLEMENTS);

	const paymentSupplements = React.useMemo(() => paymentSupplementsData?.paymentSupplements ?? [], [paymentSupplementsData?.paymentSupplements]);

	const timeTrackings = React.useMemo(() => {
		return timeTrackingData?.timeTrackings ?? [];
	}, [timeTrackingData]);

	const [filteredData, setFilteredData] = React.useState<TimeRegOverview[]>([]);

	React.useEffect(() => {
		setFilteredData(
			timeTrackings.map(
				(tracking): TimeRegOverview => ({
					id: tracking.id,
					date: tracking.date,
					user: tracking.user,
					case: tracking.case,
					jobTaskNo: tracking.jobTaskNo,
					jobTaskName: tracking.jobTaskName ?? '',
					workTypeCode: tracking.workTypeCode,
					onCall: tracking.onCall,
					remark: tracking.remark,
					hours: tracking.hours,
					addonLines: tracking.addonLines?.map(l => ({
						paymentSupplementCode: l?.paymentSupplementCode ?? '',
						paymentSupplementName: l?.paymentSupplementName ?? '',
						hours: l?.hours ?? 0,
						onCall: l?.onCall ?? false,
					})),
				}),
			),
		);
	}, [timeTrackings, userContext.user?.id]);

	const fuse = React.useMemo(
		() =>
			new Fuse(filteredData, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['user.name', 'user.email', 'case.erpNo', 'case.debitor.company', 'jobTaskNo'],
			}),
		[filteredData],
	);

	const filteredTrackings =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ 'user.name': searchToken },
								{ 'user.email': searchToken },
								{ 'case.erpNo': searchToken },
								{ 'case.debitor.company': searchToken },
								{ jobTaskNo: searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: filteredData;

	const onCallDisablerFlag = useFlag(FeatureFlagEnums.ON_CALL_DISABLER);
	return (
		<div>
			<BoxContainer>
				<Box full>
					<AllRegsOverviewFilter setFilterTerm={setSearchTerm} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
					<Button />
					<Table
						data={filteredTrackings ?? []}
						columns={[
							{
								label: 'common.date',
								numeric: true,
								selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
								sortFn: (a, b) => a.date.localeCompare(b.date),
							},
							{
								label: 'common.name',
								selectFn: t => <p>{t.user.name ? t.user.name : ''}</p>,
								sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
							},
							{
								label: 'common.case',
								selectFn: t => (
									<div>
										<p>{t.case ? `${t.case.erpNo}` : ''}</p>
										<p>
											{t.case
												? `${t.case.damage.contact.address.road} ${t.case.damage.contact.address.houseNumber}, ${t.case.damage.contact.address.postalCode} ${t.case.damage.contact.address.city}`
												: ''}
										</p>
										<p>{t.case ? `${t.case.damage.category.name}/${t.case.damage.cause.name}` : ''}</p>
									</div>
								),
								sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
							},
							{
								label: 'offer.jobTask',
								selectFn: t => <p>{t.jobTaskName}</p>,
								sortFn: (a, b) => (a.jobTaskName ?? '').localeCompare(b.jobTaskName ?? ''),
							},
							{
								label: 'timeRegistration.onCall',
								hideColumn: onCallDisablerFlag,
								selectFn: tr => <p>{t(tr.onCall ? 'common.yes' : 'common.no')}</p>,
							},
							{
								label: 'common.comment',
								noTruncate: true,
								classNameTh: 'w-1/3',
								selectFn: t => <p>{t.remark}</p>,
							},
							{
								label: 'common.hours',
								selectFn: t => <p>{t.hours}</p>,
								sortFn: (a, b) => (a.hours.toString() ?? '').localeCompare(b.hours.toString() ?? ''),
								numeric: true,
							},
							{
								label: 'common.debitor',
								selectFn: t => <p>{t.case?.debitor.company}</p>,
								sortFn: (a, b) => (a.case?.debitor.company ?? '').localeCompare(b.case?.debitor.company ?? ''),
							},
						]}
						sublineData={filteredTrackings.map(s => {
							return s.addonLines ?? [];
						})}
						subColumns={[
							{
								label: 'common.date',
								selectFn: s => '-',
							},
							{
								label: 'common.user',
								selectFn: s => '-',
							},
							{
								label: 'common.case',
								selectFn: s => <p>{`${t('timeRegistration.supplement')}: ${s?.paymentSupplementName} (${s?.paymentSupplementCode})`}</p>,
							},
							{
								label: 'offer.jobTask',
								selectFn: s => '-',
							},
							{
								label: 'timeRegistration.onCall',
								hideColumn: onCallDisablerFlag,
								selectFn: s => <p>{t(s?.onCall ? 'common.yes' : '-')}</p>,
							},
							{
								label: 'common.comment',
								selectFn: s => '-',
							},
							{
								label: 'common.hours',
								selectFn: s => '-',
							},
							{
								label: 'common.debitor',
								selectFn: s => <p>{`${s?.hours} ${paymentSupplements.find(ps => ps?.code === s?.paymentSupplementCode)?.uOM ?? ''}`}</p>,
							},
						]}
						keySelector={t => t.id}
						noDataFoundText="timeRegistration.noTimeRegFound"
						loading={typeof timeTrackingData === 'undefined' && loadingTimeTracking}
					/>
				</Box>
			</BoxContainer>
		</div>
	);
};

export default AllRegistrationsOverview;
