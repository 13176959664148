import { useQuery } from '@apollo/client';
import Loading from '@ssg/common/Components/Loading';
import Table from '@ssg/common/Components/Table';
import Textarea from '@ssg/common/Components/Textarea';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllAssortments, AllAssortmentsVariables, GetInvoiceApproval, GetInvoiceApprovalVariables } from '../../../GraphQL';
import { calculateLinePrice } from '../../SingleCase/Invoice/calculateLinePrice';
import SummaryTable from '../../SingleCase/Invoice/SummaryTable';
import { getEnvFeeOnInvoice, /*hasMaximumEnvironmentalFeeAmountForDebitor,*/ shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { addUOM, filterInvoiceLines } from '../../SingleCase/Invoice/InvoiceStepView';
import { notNull } from '@ssg/common/Helpers/notNull';

const GET_INVOICE_APPROVAL = loader('src/GraphQL/Invoices/GetInvoiceApproval.gql');
const GET_ALL_ASSORTMENTS = loader('src/GraphQL/Invoices/GetAllAssortments.gql');

interface Props {
	caseId: string;
	caseERPno: string;
	invoiceNo: string;
	isScreening: boolean;
	rejectionComment: string | null;
	setRejectionComment: (comment: string) => void;
	totalCaseCost: number;
}

const TextWithTitle: React.FC<{ title: string; text: string }> = ({ title, text }) => (
	<div>
		<p className="text-sm font-semibold">{title}</p>
		<p className="text-sm">{text}</p>
	</div>
);

const InvoiceApprovalModalBody: React.FC<Props> = ({ caseId, invoiceNo, isScreening, rejectionComment, setRejectionComment, totalCaseCost }) => {
	const { t } = useTranslation();

	const { data, loading, error } = useQuery<GetInvoiceApproval, GetInvoiceApprovalVariables>(GET_INVOICE_APPROVAL, { variables: { caseId } });
	const _case = data?.case;
	const invoiceReasonCodes = data?.invoiceReasonCodes;
	const invoice = data?.case.invoices.find(invoice => invoice.erpReferenceNo === invoiceNo);
	const enabledEnvironmentalFeeFlag = useFlag(FeatureFlagEnums.ENVIRONMENTAL_FEE);
	const { data: assortmentData, loading: assormentsLoading } = useQuery<AllAssortments, AllAssortmentsVariables>(GET_ALL_ASSORTMENTS, {
		variables: {
			assortmentCode: _case?.debitor.assortmentCode ?? '',
		},
		skip: typeof _case === 'undefined',
	});
	const screeningLines = React.useMemo(() => _case?.screenings.flatMap(s => s.lines.map(l => ({ label: l.uom, value: l.erpReferenceId }))) ?? [], [_case?.screenings]);

	const invoiceAssortments = React.useMemo(() => assortmentData?.allAssortments?.filter(notNull) ?? [], [assortmentData?.allAssortments]);
	if (loading || assormentsLoading || !_case || !invoiceReasonCodes)
		return (
			<div className="relative h-40 w-full">
				<Loading />
			</div>
		);
	if (error) return <p>error</p>;
	if (!invoice) return <p>no invoice</p>;

	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(_case.debitor.environmentFeePercentage);
	//const hasMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(_case.debitor.maxEnvironmentFeeAmount);
	const totalPrice = invoice.lines.map(calculateLinePrice).reduce((a, b) => a + b, 0);
	const totalEnvFee = getEnvFeeOnInvoice(invoice.lines, _case.debitor);
	//const totalEnvFee =
	const totalCost = totalCaseCost; //invoice.lines.map(calculateCostLinePrice).reduce((a, b) => a + b, 0);
	const contributionRatio = ((totalPrice - totalCost) / totalPrice) * 100;

	return (
		<div className="flex flex-col gap-2 text-blue">
			<div className="flex gap-4">
				<TextWithTitle title={t('myPage.invoiceApproval.caseNo')} text={_case.erpNo} />
				<TextWithTitle title={t('myPage.invoiceApproval.invoiceNo')} text={invoice.erpReferenceNo} />
				<TextWithTitle title={t('myPage.invoiceApproval.category')} text={invoice.category} />
				<TextWithTitle title={t('myPage.invoiceApproval.debitor')} text={_case.debitor.company} />
				<TextWithTitle title={t('myPage.invoiceApproval.createdBy')} text={invoice.createdBy?.name ?? t('myPage.invoiceApproval.createdByUnknown')} />
				<TextWithTitle title={t('myPage.invoiceApproval.projectManager')} text={_case.projectManager?.name ?? t('myPage.invoiceApproval.projectManagerUnknown')} />
				<TextWithTitle title={t('myPage.invoiceApproval.ssgDepartment')} text={`${_case.ssgDepartment.name} (${_case.ssgDepartment.departmentNumber})`} />
			</div>
			{invoice.invoiceDescription.length > 0 && <TextWithTitle title={t('common.description')} text={invoice.invoiceDescription} />}

			<div>
				<p className="text-blue font-semibold">{t('myPage.invoiceApproval.modal.lines')}</p>

				<SummaryTable lines={addUOM(filterInvoiceLines(invoice.lines), invoiceAssortments, screeningLines)} isScreening={isScreening} />

				<div className="flex justify-end gap-2 pt-4">
					<p>
						{t('myPage.invoiceApproval.totalPrice')}: {addThousandSeperator(totalPrice)}
					</p>
					{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
						<p>
							{t('case.invoice.envFeeTotal')}: {addThousandSeperator(totalEnvFee)}
						</p>
					)}
					<p>
						{t('myPage.invoiceApproval.totalCaseCost')}: {addThousandSeperator(totalCost)}
					</p>
					<p>
						{t('myPage.invoiceApproval.contributionRatio')}:{' '}
						{contributionRatio.toLocaleString(undefined, {
							maximumFractionDigits: 2,
						})}
						%
					</p>
				</div>
			</div>

			<hr className="border-gray-200" />

			<div>
				<p className="text-blue font-semibold">{t('myPage.invoiceApproval.modal.bypassReasons')}</p>

				<Table
					data={invoice.validationRuleBypasses}
					className="max-h-max"
					keySelector={rc => rc.rule}
					columns={[
						{
							label: 'case.invoice.rule',
							selectFn: rc => t(`case.invoice.${rc.rule}`)?.toString() ?? '',
						},
						{
							label: 'case.invoice.reasonCode',
							selectFn: rc => invoiceReasonCodes.find(irc => irc.id === rc.reasonCode)?.name ?? '',
						},
						{
							label: 'case.invoice.comment',
							selectFn: rc => rc.comment ?? '',
						},
					]}
				/>
			</div>

			<hr className="border-gray-200" />

			<div>
				<Textarea title="myPage.invoiceApproval.modal.rejectionComment" name="rejectionComment" value={rejectionComment ?? ''} onChange={e => setRejectionComment(e.target.value)} />
			</div>
		</div>
	);
};

export default InvoiceApprovalModalBody;
