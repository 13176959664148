import React from 'react';
import { FileType, getFileTypeFromUrl } from '@ssg/common/Helpers/fileTypeHelper';
import { appendNo } from '../../../helper';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import {
	EsdhFileFragmentFragment,
	FileInput,
	GetCaseFilesDocument,
	GetCaseFilesQuery,
	GetCaseFilesQueryVariables,
	useGetCaseFilesQuery,
	useUpdatePictureCommentMutation,
	useUploadCaseFilesMutation,
} from '@ssg/common/GraphQL/indexV2';

interface UseCaseImagesResult {
	upload(fileInput: FileInput): Promise<EsdhFileFragmentFragment>;
	uploadMultiple(filesInput: FileInput[]): Promise<EsdhFileFragmentFragment[]>;
	changeComment(fileId: string, comment: string): Promise<string>;
	images: EsdhFileFragmentFragment[];
	loadingImages: boolean;
	isUploading: boolean;
}

function getFileModifiedDateTime(file: EsdhFileFragmentFragment): number {
	const modified = file.metadata.find(m => m.key === 'Modified')?.value;

	if (modified) {
		const date = new Date(modified);
		return date.getTime();
	}

	return 0;
}

function extractImages(files: EsdhFileFragmentFragment[]): EsdhFileFragmentFragment[] {
	return files.filter(file => getFileTypeFromUrl(file.url) === FileType.Image).sort((a, b) => getFileModifiedDateTime(b) - getFileModifiedDateTime(a));
}

export default function useCaseImages(caseNo: string, onUploadComplete?: (files: EsdhFileFragmentFragment[]) => unknown): UseCaseImagesResult {
	const [isUploading, setIsUploading] = React.useState(false);

	// Get the driving slip files and extract the images
	const { data, loading: loadingImages } = useGetCaseFilesQuery({
		fetchPolicy: 'cache-first',
		variables: {
			caseErpNo: caseNo,
			folder: caseNo,
		},
		skip: caseNo.length === 0,
	});
	const fileVersionAppendFlag = useFlag(FeatureFlagEnums.FILE_VERSION_APPEND);

	const images = extractImages(data?.caseFiles ?? []);

	// Setup file upload
	const [uploadCaseFiles] = useUploadCaseFilesMutation();

	const uploadMultiple = async (filesInput: FileInput[]): Promise<EsdhFileFragmentFragment[]> => {
		setIsUploading(true);
		if (fileVersionAppendFlag) {
			const existingFileNames = data?.caseFiles.map(d => d.name) ?? [];

			for (const file of filesInput) {
				if (existingFileNames.includes(file.filename)) {
					file.filename = appendNo(file.filename, existingFileNames);
				}
				existingFileNames.push(file.filename);
			}
		}
		const result = await uploadCaseFiles({
			variables: {
				folder: caseNo,
				files: filesInput,
			},
			update: (cache, mutationResult) => {
				if (mutationResult.data == null) return;
				onUploadComplete?.(mutationResult.data.uploadCaseFiles);

				const cachedRequest = cache.readQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
					query: GetCaseFilesDocument,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
				});

				if (cachedRequest == null || cachedRequest.caseFiles == null) return;

				cache.writeQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
					query: GetCaseFilesDocument,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
					data: {
						caseFiles: [...mutationResult.data.uploadCaseFiles, ...cachedRequest.caseFiles],
					},
				});
			},
		});

		setIsUploading(false);

		if (result.data?.uploadCaseFiles == null) return [];

		return extractImages(result.data?.uploadCaseFiles).filter(image => filesInput.some(input => input.filename === image.name));
	};

	const upload = async (fileInput: FileInput) => {
		const result = await uploadMultiple([fileInput]);
		return result[0];
	};

	const [updatePictureComment] = useUpdatePictureCommentMutation();

	const changeComment = async (fileId: string, comment: string): Promise<string> => {
		await updatePictureComment({
			variables: {
				fileID: fileId,
				comment: comment,
			},
			update: (cache, mutationResult) => {
				if (mutationResult.data == null) return;

				const cachedRequest = cache.readQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
					query: GetCaseFilesDocument,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
				});

				if (cachedRequest == null || cachedRequest.caseFiles == null) return;
				const indexToChange = cachedRequest.caseFiles.findIndex(cf => cf.fileId === fileId);

				const replaceFiles = [
					...cachedRequest.caseFiles.slice(0, indexToChange),
					{
						...cachedRequest.caseFiles[indexToChange],
						comments: comment,
					},
					...cachedRequest.caseFiles.slice(indexToChange + 1),
				];

				cache.writeQuery<GetCaseFilesQuery, GetCaseFilesQueryVariables>({
					query: GetCaseFilesDocument,
					variables: {
						caseErpNo: caseNo,
						folder: caseNo,
					},
					data: {
						caseFiles: replaceFiles,
					},
				});
			},
		});

		return comment;
	};

	return {
		upload,
		uploadMultiple,
		images,
		loadingImages,
		isUploading,
		changeComment,
	};
}

