import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceAssortment, InvoiceLine } from './InvoiceModal';
import SummaryTable from './SummaryTable';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { useQuery } from '@apollo/client';
import { GetDebitor_debitor, GetInvoiceReasonCodes, ValidationRuleBypassInput } from '../../../GraphQL';
import { loader } from 'graphql.macro';
import Table from '@ssg/common/Components/Table';
import { InvoiceCategory } from '@ssg/common/GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { DEFAULT_ENVFEE_NAME, getEnvFeeOnInvoice, hasMaximumEnvironmentalFeeAmountForDebitor, shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { addUOM, filterInvoiceLines } from './InvoiceStepView';
import { SelectOption } from '@ssg/common/Helpers/Helpers';

const GET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/GetInvoiceReasonCodes.gql');

interface Props {
	category: InvoiceCategory;
	lines: InvoiceLine[];
	reasonCodes: ValidationRuleBypassInput[];
	desciption: string;
	debitor: GetDebitor_debitor;
	assortments: InvoiceAssortment[];
	screeningLines: SelectOption[];
}

const InvoiceStepSummary: React.FC<Props> = ({ category, lines, reasonCodes, desciption, debitor, assortments, screeningLines }) => {
	console.log('🚀 ~ lines:', lines);
	const { t } = useTranslation();
	const enabledEnvironmentalFeeFlag = useFlag(FeatureFlagEnums.ENVIRONMENTAL_FEE);

	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);
	const hasMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);
	// This should be cached from the previous page
	const { data: invoiceReasonCodesData, loading: invoiceReasonCodesLoading } = useQuery<GetInvoiceReasonCodes>(GET_INVOICE_REASON_CODES, {
		fetchPolicy: 'cache-first',
	});

	const totalPrice = lines.reduce((price, line) => price + (line.lineAmount ?? 0), 0);
	const totalEnvFee = getEnvFeeOnInvoice(lines, debitor);

	return (
		<>
			<div>
				<p className="mb-2 font-semibold">{t('case.invoice.reviewInvoice')}</p>
				{desciption.length > 0 && (
					<div className="text-blue text-sm">
						<h2 className="font-semibold">{t('common.description')}</h2>
						<p className="overflow-y-auto" style={{ maxHeight: '10rem' }}>
							{desciption}
						</p>
					</div>
				)}
				<div>
					<SummaryTable lines={addUOM(filterInvoiceLines(lines), assortments, screeningLines)} isScreening={category === InvoiceCategory.SCREENING} />
				</div>
				{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
					<div className="mt-2 text-right">
						<p>
							{t('case.invoice.envFee')}: {debitor.environmentFeePercentage}% | {t('case.invoice.maxEnvFee')}: {hasMaxEnvFeeAmount ? debitor.maxEnvironmentFeeAmount : 'N/A'}
						</p>
						<p>
							{t('case.invoice.envFeeTotal')}: {totalEnvFee}
						</p>
					</div>
				)}
				<p className="my-2 text-right">
					{t('case.invoice.line.total')} {addThousandSeperator(totalPrice)}
				</p>
			</div>

			{reasonCodes.length > 0 && !invoiceReasonCodesLoading && (
				<div className="border-t-1 mt-6 border-gray-200 pt-6">
					<p className="mb-2 font-semibold">{t('case.invoice.reviewBypassReasons')}</p>

					<div style={{ maxHeight: '70vh' }}>
						<Table
							data={reasonCodes}
							className="max-h-max"
							keySelector={rc => rc.rule}
							columns={[
								{
									label: 'case.invoice.rule',
									selectFn: rc => t(`case.invoice.${rc.rule}`)?.toString() ?? '',
								},
								{
									label: 'case.invoice.reasonCode',
									selectFn: rc => invoiceReasonCodesData?.invoiceReasonCodes.find(irc => irc.id === rc.reasonCode)?.name ?? '',
								},
								{
									label: 'case.invoice.comment',
									selectFn: rc => rc.comment ?? '',
								},
							]}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default InvoiceStepSummary;
