import React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface Props {
    dataTitle: string;
    data: number;
    dataTitle2?: string;
    data2?: number;
    loading: boolean;
	className?: string;
    small?: boolean;
    icon?: IconDefinition;
    iconSize?: FontAwesomeIconProps['size'];
}

const SummaryCard: React.FC<Props> = ({ dataTitle, data, dataTitle2, data2, loading, icon, iconSize, small = false }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('bg-white rounded-lg p-4', {
            'm-2': small,
        })}>
            <div className={classNames('flex items-center', {
                'min-w-40 justify-between': !small,
            })}>
                {loading &&
                    <div className='animate-pulse'>
                        <div className='bg-gray-200 rounded h-10 w-16 mb-2'></div>
                        <div className='bg-gray-200 rounded h-5 w-16'></div>
                    </div>
                }

                {!loading &&
                    <div>
                        <div className={classNames({
                            'text-3xl leading-8': !small,
                            'text-2xl leading-5': small,
                        })}>{data}</div>

                        <div className={classNames({
                            'text-sm': !small,
                            'text-xs': small,
                        })}>
                            {t(dataTitle)}
                        </div>
                    </div>
                }

                {icon &&
                    <div>
                        <FontAwesomeIcon icon={icon} size={iconSize} className='ml-6' />
                    </div>
                }
            </div>

            {(typeof data2 !== 'undefined' && data2 > 0) &&
                <div className="flex items-center mt-2">
                    <div className="text-1xl leading-5 mr-2">{data2}</div>

                    <div className="text-xs">
                        {t(dataTitle2 ?? '')}
                    </div>
                </div>
            }
        </div>
    );
};
export default SummaryCard;
