import React from 'react';
import {
	CreateTimeTracking,
	CreateTimeTrackingVariables,
	DeleteTimeTracking,
	DeleteTimeTrackingVariables,
	GetWebCasesByErpNo,
	GetWebCasesByErpNoVariables,
	GetWebCasesForTimeTracking,
	GetWebCasesForTimeTrackingVariables,
	GetDrivingSlipCategories,
	GetWebDrivingSlips,
	GetWebDrivingSlipsVariables,
	GetWebDrivingSlips_drivingSlips,
	GetPaymentSupplements,
	GetTimeTrackingsForUser_timeTrackingsForUser_case,
	GetTimeTrackings_timeTrackings,
	JobTasks,
	JobTasksVariables,
	OpenRejectedDay_openRejectedDay,
	SubmitDay,
	SubmitDayVariables,
	SupplementType,
	TimeTrackingInputType,
	TimeTrackingStatus,
	TrackingType,
	UserBasisTime,
	UserBasisTimeVariables,
} from '../../GraphQL';
import { formatDate, SelectOption } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { DateTime, Interval } from 'luxon';
import { ITimeRegistration } from '../../Schemas/ITimeRegistration';
import { yupResolver } from '@hookform/resolvers/yup';
import { TimeRegistrationSchema } from '../../Schemas/TimeRegistrationSchema';
import { useFlag } from '@unleash/proxy-client-react';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import Checkbox from '@ssg/common/Components/Checkbox';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import UserContext from '../../UserContext';
import classNames from 'classnames';
import Input from '@ssg/common/Components/Input';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Textarea from '@ssg/common/Components/Textarea';
import SupplementLine from './SupplementLine';
import TextButton from '@ssg/common/Components/TextButton';
import Button from '@ssg/common/Components/Button';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_CASES = loader('src/GraphQL/Cases/GetWebCases.gql');
const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');
const GET_JOB_TASKS = loader('src/GraphQL/Offer/GetJobTask.gql');
const GET_PAYMENT_SUPPLEMENTS = loader('src/GraphQL/TimeTracking/GetPaymentSupplements.gql');
const GET_DRIVINGSLIPS_NO_CASE = loader('src/GraphQL/DrivingSlips/GetWebDrivingSlipsNoCase.gql');
const GET_DRIVINGSLIP_CATEGORIES = loader('src/GraphQL/DrivingSlips/GetDrivingSlipCategories.gql');
const USER_BASIS_TIME = loader('src/GraphQL/TimeTracking/UserBasisTime.gql');
const DELETE_TIME_TRACKING = loader('src/GraphQL/TimeTracking/DeleteTimeTracking.gql');
const CREATE_TIME_TRACKING = loader('src/GraphQL/TimeTracking/CreateTimeTracking.gql');
const SUBMIT_DAY = loader('src/GraphQL/TimeTracking/SubmitDay.gql');

const activityCodes: SelectOption[] = [
	{ label: 'Intern', value: 'INTERN' },
	{ label: 'Køreseddel', value: 'DRIVINGSLIP' },
	{ label: 'Sag', value: 'CASE' },
];

interface Props {
	open: boolean;
	close: () => void;
	data?: OpenRejectedDay_openRejectedDay[];
	date: Date;
	userId: string;
	refetch: (date: Date) => Promise<void>;
}

const RejectedRegistrationModal: React.FC<Props> = ({ open, close, data, userId, refetch, date }) => {
	const { t } = useTranslation();

	const rejectedHoursUserSelect = useFlag(FeatureFlagEnums.REJECTED_HOURS_USER_SELECT);

	const userContext = React.useContext(UserContext);

	const [selectedLine, setSelectedLine] = React.useState<GetTimeTrackings_timeTrackings>();

	const [jobTask, setJobTask] = React.useState('');
	const [timeRegistration, setTimeRegistration] = React.useState(false);
	const [jobSearchText, setJobSearchText] = React.useState('');

	const [showSupplement, setShowSupplement] = React.useState(false);
	const [supplementLines, setSupplementLines] = React.useState<string[]>([]);

	const [indicateOnCall, setIndicateOnCall] = React.useState(false);

	const [showOvertimeWarning, setShowOvertimeWarning] = React.useState(false);

	const [selectedDrivingSlip, setSelectedDrivingSlip] = React.useState<GetWebDrivingSlips_drivingSlips>();
	const [overEstimate, setOverEstimate] = React.useState(false);
	const [showCloseDayModal, setShowCloseDayModal] = React.useState(false);

	const [caseSearchText, setCaseSearchText] = React.useState('');
	const [selectedCase, setSelectedCase] = React.useState<SelectOption | undefined>();

	const formRef = React.useRef<HTMLFormElement>(null);
	const alllowOvertime = React.useRef(false);

	const formObj = useForm<ITimeRegistration>({
		resolver: yupResolver(TimeRegistrationSchema),
		defaultValues: {
			substitute: false,
		},
		mode: 'all',
		reValidateMode: 'onChange',
		shouldUnregister: false,
	});

	const { handleSubmit, register, getValues, setValue, watch, reset, errors, control } = formObj;

	const remoteSubmit = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	const drivingSlipWatch = watch('drivingSlip');

	const [deleteTimeTracking] = useMutation<DeleteTimeTracking, DeleteTimeTrackingVariables>(DELETE_TIME_TRACKING);
	const [createTimeTracking, { loading: createTimeTrackingLoading }] = useMutation<CreateTimeTracking, CreateTimeTrackingVariables>(CREATE_TIME_TRACKING);
	const [submitDay] = useMutation<SubmitDay, SubmitDayVariables>(SUBMIT_DAY);

	const timetrackingCaseErpNos: string[] | undefined =
		data
			?.flatMap(t => t.case)
			.filter((tc): tc is GetTimeTrackingsForUser_timeTrackingsForUser_case => tc !== null)
			.map(tc => tc.erpNo) ?? [];

	/* FORKERT GQL */
	const { data: caseData, loading: loadingCases } = useQuery<GetWebCasesForTimeTracking, GetWebCasesForTimeTrackingVariables>(GET_CASES, {
		fetchPolicy: 'cache-and-network',
		skip: typeof data === 'undefined' || timetrackingCaseErpNos.length === 0,
		variables: {
			erpReferenceNos: timetrackingCaseErpNos,
			includeEconomy: false,
		},
	});

	const cases = React.useMemo(() => {
		return (
			caseData?.cases
				.filter(c => c.timeRegistration)
				.map(
					(c): SelectOption => ({
						label: `${c.erpNo} - ${c.damage.contact.address.road} ${c.damage.contact.address.houseNumber}, ${c.damage.contact.address.postalCode} ${c.damage.contact.address.city} (${c.damage.category.name}/${c.damage.cause.name})`,
						value: c.id,
					}),
				) ?? []
		);
	}, [caseData]);

	const { data: erpNoCases } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: {
			erpReferenceNo: caseSearchText,
			closedCases: false,
			appliedClosed: false,
		},
		skip: caseSearchText.length < 3,
	});

	const searchedCases = React.useMemo(() => {
		return (
			erpNoCases?.cases
				.filter(c => c.timeRegistration)
				.map(
					(c): SelectOption => ({
						label: `${c.erpNo} - ${c.damage.contact.address.road} ${c.damage.contact.address.houseNumber}, ${c.damage.contact.address.postalCode} ${c.damage.contact.address.city} (${c.damage.category.name}/${c.damage.cause.name})`,
						value: c.id,
					}),
				) ?? []
		);
	}, [erpNoCases]);

	const { data: drivingSlipsData } = useQuery<GetWebDrivingSlips, GetWebDrivingSlipsVariables>(GET_DRIVINGSLIPS_NO_CASE, {
		variables: {
			case: erpNoCases?.cases.find(c => c.erpNo === selectedCase?.value ?? '')?.id,
		},
		skip: jobTask !== 'DRIVINGSLIP' || typeof selectedCase === 'undefined' || selectedCase?.value === 'INTERN',
	});

	const drivingSlips = React.useMemo<SelectOption[]>(
		() =>
			[
				...(drivingSlipsData?.drivingSlips.map<SelectOption>(d => ({
					label: dateToDateTimeString(new Date(d.start)).replace(':', '.'),
					value: d.id,
				})) ?? []),
			].filter((ds, i, a) => a.findIndex(ads => ads.value === ds.value) === i),
		[drivingSlipsData],
	);

	const { data: jobTaskData, loading: jobTaskLoading } = useQuery<JobTasks, JobTasksVariables>(GET_JOB_TASKS, {
		variables: {
			erpReferenceNo: selectedCase?.label.includes('-') ? selectedCase?.label.split('-')[0] ?? '' : selectedCase?.value ?? '',
		},
	});

	const { data: drivingSlipCategoriesData } = useQuery<GetDrivingSlipCategories>(GET_DRIVINGSLIP_CATEGORIES);

	const drivingSlipJobTasks = React.useMemo(() => {
		const category = drivingSlipsData?.drivingSlips.find(ds => ds.id === drivingSlipWatch)?.category;

		if (typeof category === 'undefined' || category === null || category?.length === 0) {
			return;
		}

		return drivingSlipCategoriesData?.drivingSlipCategories.find(dsc => dsc.code === category)?.erpReferenceTasks;
	}, [drivingSlipCategoriesData?.drivingSlipCategories, drivingSlipWatch, drivingSlipsData?.drivingSlips]);

	const jobTasks = React.useMemo(
		() =>
			jobTaskData?.jobTasks
				?.filter(jt => typeof drivingSlipJobTasks === 'undefined' || drivingSlipJobTasks.includes(jt?.jobTaskNo ?? '?'))
				.filter(jw => jw?.workTypeCode.length !== 0)
				.map<SelectOption>(j => ({
					label: j?.description ?? '',
					value: j?.jobTaskNo ?? '',
				})) ?? [],
		[drivingSlipJobTasks, jobTaskData?.jobTasks],
	);

	const { data: paymentSupplementsData } = useQuery<GetPaymentSupplements>(GET_PAYMENT_SUPPLEMENTS);

	// Supplement data for showing supplement code, description etc. unfiltered by blocked
	const paymentSupplements = React.useMemo(
		() =>
			paymentSupplementsData?.paymentSupplements.filter(ps => {
				switch (jobTask) {
					case 'INTERN':
						return ps?.templateJobNo === '+INTERN';
					case 'DRIVINGSLIP':
					case 'CASE':
						return ps?.templateJobNo === '+STANDARD';
					default:
						return ps?.templateJobNo;
				}
			}) ?? [],
		[jobTask, paymentSupplementsData?.paymentSupplements],
	);

	// Filters selectable supplements based on types: intern, case or drivingslip
	const paymentSupplementsSelect = React.useMemo(
		() =>
			paymentSupplementsData?.paymentSupplements
				.filter(s => s?.blocked === false)
				//.filter(s => s?.code !== '2100')
				.filter(s => s?.code !== '2101')
				.filter(ps => {
					switch (jobTask) {
						case 'INTERN':
							return ps?.templateJobNo === '+INTERN';
						case 'DRIVINGSLIP':
						case 'CASE':
							return ps?.templateJobNo === '+STANDARD';
						default:
							return ps?.templateJobNo;
					}
				})
				.map<SelectOption>(s => ({
					label: `${s?.description} (${s?.code})`,
					value: s?.code ?? '',
				})) ?? [],
		[jobTask, paymentSupplementsData?.paymentSupplements],
	);

	const { data: basisTimeData } = useQuery<UserBasisTime, UserBasisTimeVariables>(USER_BASIS_TIME, {
		fetchPolicy: 'cache-and-network',
		variables: {
			user: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '', // TODO: Change to parsed userID
			date: formatDateForInput(date),
		},
	});

	// Reset form and state values
	const resetForm = React.useCallback((): void => {
		setJobTask('');
		setJobSearchText('');
		setIndicateOnCall(false);
		setShowSupplement(false);
		setSupplementLines([]);
		alllowOvertime.current = false;
		setCaseSearchText('');
		reset();
	}, [reset]);

	// Reset supplments on showSupplements change
	React.useEffect(() => {
		if (showSupplement) {
			return;
		}
		setSupplementLines([]);

		// Dirty solution for clearing addonLines because formstate is not set or updating when resetting without timeout
		setTimeout(() => {
			setValue('addonLines', undefined);
		}, 400);
	}, [showSupplement, setValue]);

	const userRegisteredHours = data?.reduce((acc, curr) => acc + (curr.hours ?? 0), 0);

	const beforeSubmit = (data: TimeTrackingInputType) => {
		// If registrations on selected date exceeds userBasisTime, show modal with warning
		const accHoursOnSelectedDate = (userRegisteredHours ?? 0) + data.hours;
		const basisTime = typeof basisTimeData?.userBasisTime !== 'undefined' ? basisTimeData?.userBasisTime : 0;

		if (accHoursOnSelectedDate > basisTime && !alllowOvertime.current.valueOf()) {
			setShowOvertimeWarning(true);
		} else {
			setShowOvertimeWarning(false);
			onSubmit(data);
		}
	};

	const onSubmit = async (data: TimeTrackingInputType) => {
		const track = caseData?.cases.find(c => c.id === data.case)?.track ?? 0;

		if (typeof data.drivingSlip === 'string' && data.drivingSlip.length === 0) {
			data.drivingSlip = null;
		}

		const addonLinesArray =
			data.addonLines?.map<TimeTrackingInputType>(l => ({
				user: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
				date: formatDateForInput(new Date(data.date)),
				case: data.case,
				jobNo: data.jobNo,
				jobTaskNo: data.jobTaskNo,
				workTypeCode: l.workTypeCode !== '' ? l.workTypeCode : data.workTypeCode,
				remark: l.paymentSupplementName,
				paymentSupplementCode: l.paymentSupplementCode,
				paymentSupplementName: l.paymentSupplementName,
				supplementUom: l.supplementUom,
				supplementType: l.supplementType ?? null,
				trackingType: TrackingType.TIMEREGISTRATION,
				hours: l.supplementType === SupplementType.PIECEWORK ? 1 : l.hours,
				pieceworkAmount: l.supplementType === SupplementType.PIECEWORK ? l.hours : 0,
			})) ?? [];

		// Overtime will not be added to rejected hours
		// The problem being that registrations will be duplicated when editing and therefore always be above the basis time and trigger overtime

		try {
			await createTimeTracking({
				variables: {
					timeTracking: {
						user: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
						date: formatDateForInput(new Date(data.date)),
						case: data.case,
						jobNo: data.jobNo,
						trackingType: TrackingType.TIMEREGISTRATION,
						timeTrackingStatus: track === 1 ? TimeTrackingStatus.APPROVED : TimeTrackingStatus.AWAITING,
						drivingSlip: data.drivingSlip,
						hours: data.hours,
						workTypeCode: data.workTypeCode,
						jobTaskNo: data.jobTaskNo,
						onCall: data.onCall ?? false,
						jobTaskName: jobTasks.find(jt => jt.value === data.jobTaskNo)?.label ?? '',
						remark: data?.remark ?? '',
						supplementType: SupplementType.TIMEREGISTRATION,
						addonLines: addonLinesArray,
					},
				},
			});

			refetch(date);
		} catch (e) {
			// console.log(e);
			// alert(t('error.timereg'));
		}

		setSelectedLine(undefined);
		resetForm();
	};

	const closeDay = async () => {
		const date = getValues('date');

		try {
			await submitDay({
				variables: {
					timeTracking: {
						date: date.toString(),
						trackingType: TrackingType.DAYCLOSED,
						user: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
						addonLines: [],
						hours: 0,
					},
				},
			});
		} catch (e) {
			// console.log(e);
			// alert(t('error.timereg'));
		}
		setShowCloseDayModal(false);
	};

	// Combined all useEffects with same dependency
	React.useEffect(() => {
		resetForm();

		setJobTask(selectedLine?.jobNo?.toUpperCase() === 'INTERN' ? 'INTERN' : selectedLine?.drivingSlip?.id !== undefined ? 'DRIVINGSLIP' : typeof selectedLine !== 'undefined' ? 'CASE' : '');

		// Autofills supplements based on selected timereg. line
		setShowSupplement((selectedLine?.addonLines?.length ?? 0) > 0);
		const subLineIds = (selectedLine?.addonLines ?? []).map(l => l?.id);

		setSupplementLines(subLineIds);
	}, [resetForm, selectedLine]);

	// Determines type of registration
	React.useEffect(() => {
		if (jobTask === 'INTERN') {
			setValue('jobNo', 'INTERN');
			setSelectedCase(activityCodes.find(ac => ac.value === 'INTERN'));
			setValue('case', undefined);
			setValue('drivingSlip', undefined);
		}

		if (jobTask === 'CASE') {
			setValue('jobNo', selectedLine?.case?.erpNo);
			setSelectedCase(cases.find(c => c.value === selectedLine?.case?.id));
			setValue('case', selectedLine?.case?.id);
			setValue('drivingSlip', undefined);
		}

		if (jobTask === 'DRIVINGSLIP') {
			setValue('jobNo', selectedLine?.case?.erpNo);
			setSelectedCase(cases.find(c => c.value === selectedLine?.case?.id));
			setValue('case', selectedLine?.case?.id);
			setValue('drivingSlip', selectedLine?.drivingSlip?.id);
		}

		setValue('jobTaskNo', selectedLine?.jobTaskNo);
		setValue('workTypeCode', selectedLine?.workTypeCode);

		setIndicateOnCall(selectedLine?.onCall ?? false);
		setValue('onCall', selectedLine?.onCall);
	}, [
		cases,
		jobTask,
		selectedLine?.id,
		selectedLine?.case?.erpNo,
		selectedLine?.case?.id,
		selectedLine?.drivingSlip?.id,
		selectedLine?.jobTaskNo,
		selectedLine?.onCall,
		selectedLine?.workTypeCode,
		setValue,
	]);

	// Controls if content can be edited?
	React.useEffect(() => {
		setTimeRegistration(caseData?.cases.find(c => c.id === selectedCase?.value)?.timeRegistration ?? true);
	}, [selectedCase, caseData]);

	const jobTaskNoWatcher = useWatch({ control, name: 'jobTaskNo' });

	// Reset jobTaskNo guides and indicate-on-call on case switch
	// const caseWatch = watch('case');
	// React.useEffect(() => {
	//     setJobSearchText('');
	//     setValue('workTypeCode', '');
	// }, [caseWatch, setValue]);

	const accOvetimeHours = data?.flatMap(a => a.addonLines).reduce((acc, curr) => acc + (curr?.workTypeCode === '2100' ? curr?.hours ?? 0 : 0), 0);

	const enabledHumanReadableDate = useFlag(FeatureFlagEnums.TIME_REG_HUMAN_READABLE_DATE);
	const onCallDisablerFlag = useFlag(FeatureFlagEnums.ON_CALL_DISABLER);

	return (
		<Modal
			title="timeRegistration.overviewTitle"
			visible={open}
			close={close}
			size={ModalSize.LARGE}
			body={
				<div className="md:block lg:flex">
					<div className="mr-4 md:w-full lg:w-1/3">
						{(selectedLine?.rejectMessage || selectedLine?.addonLines?.some(a => a.rejectMessage)) && (
							<>
								{selectedLine?.rejectMessage !== '' && (
									<div className="bg-red border-red border-1 mb-2 rounded-md bg-opacity-25 p-2">
										<p className="text-sm">
											<FontAwesomeIcon icon={faExclamationCircle} className="text-red mr-3" />
											{selectedLine?.rejectMessage}
										</p>
									</div>
								)}

								{selectedLine?.addonLines?.map(a => (
									<div key={a?.id} className="bg-red border-red border-1 mb-2 rounded-md bg-opacity-25 p-2">
										<p className="text-sm">
											<FontAwesomeIcon icon={faExclamationCircle} className="text-red mr-3" />
											{`(${a?.paymentSupplementCode}) ${a?.rejectMessage ?? ''}`}
										</p>
									</div>
								))}
							</>
						)}

						<form ref={formRef} onSubmit={handleSubmit(beforeSubmit)}>
							<div className="flex flex-col">
								<Datepicker name="date" title="common.date" value={formatDateForInput(date)} innerRef={register} readOnly errorMessage={errors.date?.message} />

								{enabledHumanReadableDate && (
									<p className="text-xs font-semibold">
										{t('common.selectedDate')}:{' '}
										{DateTime.fromFormat(formatDateForInput(date), 'yyyy-MM-dd').toLocaleString({
											weekday: 'long',
											month: 'long',
											day: '2-digit',
											year: 'numeric',
										})}
									</p>
								)}

								<Dropdown
									name="task"
									title="timeRegistration.task"
									data={[{ label: '', value: '' }, ...activityCodes]}
									required
									value={jobTask}
									innerRef={register}
									defaultValue={jobTask}
									onChange={e => {
										setJobTask(e.target.value);
										setSelectedCase(undefined);
									}}
									errorMessage={errors.task?.message}
								/>

								{jobTask !== 'INTERN' && jobTask !== '' ? ( // Check if it's case otherwise select intern time code
									<div>
										<SearchableSelect
											control={control}
											name="case"
											title="common.case"
											options={searchedCases.filter(c => c.label.toLowerCase().includes(caseSearchText.toLowerCase()))}
											searchFn={searchText => setCaseSearchText(searchText)}
											onSelect={value => {
												setValue('case', value, {
													shouldValidate: true,
												});
												setSelectedCase({
													label: '',
													value: erpNoCases?.cases.find(c => c.id === value)?.erpNo ?? '',
												});
												setValue('jobNo', [...(caseData?.cases ?? []), ...(erpNoCases?.cases ?? [])].find(c => c.id === value)?.erpNo ?? undefined);
											}}
											required
											onBlur={() => undefined}
											minInputLength={2}
											isLoading={loadingCases}
											initialSelection={selectedCase?.value === 'INTERN' ? { value: '', label: '' } : selectedCase}
											className={classNames('w-full lg:w-full', {
												'border-red border-2': !timeRegistration,
											})}
											disabled={jobTask === ''}
											errorMessage={errors.case?.message}
										/>
										<Input name="jobNo" innerRef={register} className="hidden" />
									</div>
								) : (
									<div className="hidden">
										<Input name="jobNo" title="timeRegistration.internTask" innerRef={register} defaultValue="INTERN" />
									</div>
								)}

								{selectedCase && !timeRegistration && (
									<div>
										<p className="text-red font-medium">{t('timeRegistration.stoppedOnCase')}</p>
									</div>
								)}

								<div
									className={classNames('w-full', {
										hidden: jobTask !== 'DRIVINGSLIP',
									})}
								>
									<Dropdown
										name="drivingSlip"
										title="common.drivingSlip"
										data={[
											{ label: '', value: '' },
											...drivingSlips,
											...(typeof selectedLine?.drivingSlip !== 'undefined' &&
												selectedLine.drivingSlip !== null &&
												!drivingSlips.some(ds => ds.value === selectedLine.drivingSlip?.id)
												? [
													{
														label: t('common.loading'),
														value: selectedLine.drivingSlip.id,
													},
												]
												: []),
										]}
										required
										value={selectedLine?.drivingSlip?.id}
										innerRef={register}
										defaultValue={selectedLine?.drivingSlip?.id}
										onChange={e => {
											setSelectedDrivingSlip(drivingSlipsData?.drivingSlips.find(ds => ds.id === e.target.value));
										}}
										errorMessage={errors.drivingSlip?.message}
									/>
								</div>

								<SearchableSelect
									control={control}
									name="jobTaskNo"
									title="offer.jobTask"
									options={jobTasks.filter(c => c.label.toLowerCase().includes(jobSearchText.toLowerCase())).sort((a, b) => a.label.localeCompare(b.label))}
									searchFn={searchText => setJobSearchText(searchText)}
									onSelect={value => {
										setValue('jobTaskNo', value, {
											shouldValidate: true,
										});
										setValue('workTypeCode', jobTaskData?.jobTasks?.find(t => t?.jobTaskNo === value)?.workTypeCode ?? '', { shouldValidate: true });
									}}
									initialSelection={jobTasks.find(j => j.value === selectedLine?.jobTaskNo)}
									required
									onBlur={() => undefined}
									allowEmpty
									minInputLength={-1}
									isLoading={jobTaskLoading}
									errorMessage={errors.jobTaskNo?.message}
								/>
								{!onCallDisablerFlag &&
									jobTaskNoWatcher === '0601' && ( // "Vagt og udkald timer"-task
										<>
											<Checkbox
												name="onCall"
												title="timeRegistration.indicateOnCall"
												onChange={() => setIndicateOnCall(!indicateOnCall)}
												checkedControlled={indicateOnCall}
												className="mt-2"
												innerRef={register}
											/>

											<p>{t('timeRegistration.onCallDescription')}</p>

											{/* {indicateOnCall && <p>{t('timeRegistration.onCallDescription')}</p>} */}
										</>
									)}

								{jobTaskNoWatcher === '1900' && ( // "Akkord"-task
									<p className="mt-2">{t('timeRegistration.pieceWorkDescription')}</p>
								)}

								<Input name="workTypeCode" innerRef={register} className="hidden" defaultValue={selectedLine?.workTypeCode ?? ''} />

								<Textarea
									name="remark"
									title="common.description"
									rows={2}
									maxLength={100}
									defaultValue={selectedLine?.remark ?? ''}
									innerRef={register}
									disabled={!timeRegistration}
									errorMessage={errors.remark?.message}
								/>

								<Input
									name="hours"
									title="common.hours"
									type="number"
									step={0.01}
									lang="da"
									inputMode="decimal"
									innerRef={register({ valueAsNumber: true })}
									onChange={e => {
										if (jobTask !== 'DRIVINGSLIP' || isNaN(e.target.valueAsNumber)) {
											return;
										}

										setOverEstimate(e.target.valueAsNumber > Interval.fromISO(`${selectedDrivingSlip?.start}/${selectedDrivingSlip?.end}`).length('hours'));
									}}
									onWheel={e => e.currentTarget.blur()} // Used for disabling scroll to change number value
									defaultValue={selectedLine?.hours}
									required
									disabled={!timeRegistration}
									errorMessage={errors.hours?.message}
								/>
								{overEstimate && <p className="text-orange text-sm font-semibold">{t('timeRegistration.exceedesEstimate')}</p>}

								{control.formState.dirtyFields.hours && (
									<p className="mt-2 font-bold">
										<FontAwesomeIcon icon={faExclamationCircle} /> {t('timeRegistration.rememberSupplement')}
									</p>
								)}

								<Checkbox
									name="addSupplement"
									title="timeRegistration.addSupplement"
									innerRef={register}
									onChange={() => setShowSupplement(!showSupplement)}
									checkedControlled={showSupplement}
									className="mt-2"
								/>
							</div>

							{showSupplement && (
								<div>
									<div className="border-b-1 mt-3 font-semibold">{t('timeRegistration.supplementType')}</div>
									{supplementLines.map((id, i) => (
										<SupplementLine
											key={id}
											lineIndex={i}
											data={selectedLine?.addonLines && selectedLine.addonLines[i]}
											paymentSupplements={paymentSupplements}
											paymentSupplementsSelect={paymentSupplementsSelect}
											removeLine={() => setSupplementLines(supplementLines.filter(l => l !== id))}
											subForm={formObj}
											timeRegistration
										/>
									))}

									<TextButton icon={faPlus} text="common.addLine" onClick={() => setSupplementLines(currentLines => [...currentLines, new Date().toString()])} className="mt-3" />
								</div>
							)}

							<div className="flex justify-between">
								<Button submit primary text="common.register" className="mt-3" disabled={!timeRegistration} loading={createTimeTrackingLoading} />
								<Button
									primary
									text="common.reset"
									onClick={() => {
										setSelectedLine(undefined);
										resetForm();
									}}
									className="mt-3"
								/>
							</div>
						</form>
					</div>
					<div className="flex flex-col md:w-full lg:w-2/3">
						<Table
							data={data ?? []}
							columns={[
								{
									label: 'common.date',
									numeric: true,
									selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
									sortFn: (a, b) => a.date.localeCompare(b.date),
								},
								{
									label: 'common.case',
									selectFn: t => (
										<div>
											<p>{t.case ? `${t.case.erpNo}` : ''}</p>
											<p>
												{t.case
													? `${t.case.damage.contact.address.road} ${t.case.damage.contact.address.houseNumber}, ${t.case.damage.contact.address.postalCode} ${t.case.damage.contact.address.city}`
													: ''}
											</p>
											<p>{t.case ? `${t.case.damage.category.name}/${t.case.damage.cause.name}` : ''}</p>
										</div>
									),
									sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
								},
								{
									label: 'offer.jobTask',
									selectFn: t => <p>{t.jobTaskName}</p>,
									sortFn: (a, b) => (a.jobTaskName ?? '').localeCompare(b.jobTaskName ?? ''),
								},
								{
									label: 'timeRegistration.onCall',
									hideColumn: onCallDisablerFlag,
									selectFn: tr => <p>{t(tr.onCall ? 'common.yes' : 'common.no')}</p>,
								},
								{
									label: 'common.comment',
									noTruncate: true,
									classNameTh: 'w-1/3',
									selectFn: t => <p>{t.remark}</p>,
								},
								{
									label: 'common.hours',
									selectFn: t => <p>{t.hours}</p>,
									sortFn: (a, b) => (a.hours.toString() ?? '').localeCompare(b.hours.toString() ?? ''),
									numeric: true,
								},
								{
									label: 'common.debitor',
									selectFn: t => <p>{t.case?.debitor.company}</p>,
									sortFn: (a, b) => (a.case?.debitor.company ?? '').localeCompare(b.case?.debitor.company ?? ''),
								},
								{
									label: '',
									selectFn: t =>
										t.timeTrackingStatus !== TimeTrackingStatus.APPROVED ? (
											<FontAwesomeIcon
												icon={faTrashAlt}
												size="lg"
												className="text-red cursor-pointer"
												onClick={async () => {
													await deleteTimeTracking({
														variables: { id: t.id },
													});
													refetch(date);
												}}
											/>
										) : (
											<></>
										),
								},
							]}
							sublineData={data?.map(s => s.addonLines ?? [])}
							subColumns={[
								{
									label: 'common.date',
									selectFn: s => '-',
								},
								{
									label: 'common.case',
									selectFn: s => <p>{`${t('timeRegistration.supplement')}: ${s?.paymentSupplementName} (${s?.paymentSupplementCode})`}</p>,
								},
								{
									label: 'offer.jobTask',
									selectFn: s => '-',
								},
								{
									label: 'timeRegistration.onCall',
									selectFn: s => <p>{t(s?.onCall ? 'common.yes' : '-')}</p>,
									hideColumn: onCallDisablerFlag,
								},
								{
									label: 'common.comment',
									selectFn: s => '-',
								},
								{
									label: 'common.hours',
									selectFn: s => (
										<p>
											{`${s?.supplementType === SupplementType.PIECEWORK ? s?.pieceworkAmount : s?.hours}

                                            ${paymentSupplements.find(ps => ps?.code === s?.paymentSupplementCode)?.uOM ?? ''}`}
										</p>
									),
								},
								{
									label: 'common.debitor',
									selectFn: s => '-',
								},
								{
									label: '',
									selectFn: s => '-',
								},
							]}
							onRowClick={t => {
								//setSupplementLines([]);
								setSelectedLine(t);
								// setValue('case', t.case?.id);
								// setValue('jobNo', caseData?.cases.find(c => c.id === t.case?.id)?.erpNo ?? 'INTERN');
								// setValue('jobTaskNo', t.jobTaskNo);
								// setValue('workTypeCode', t.workTypeCode);
							}}
							rowIsDisabled={t => t.timeTrackingStatus === TimeTrackingStatus.REJECTED}
							subRowIsDisabled={s => s?.timeTrackingStatus === TimeTrackingStatus.REJECTED}
							rowIsInvalid={t => t.timeTrackingStatus === TimeTrackingStatus.APPROVED}
							subRowIsInvalid={s => s?.timeTrackingStatus === TimeTrackingStatus.APPROVED}
							keySelector={t => t.id}
							noDataFoundText="timeRegistration.noTimeRegFound"
							loading={createTimeTrackingLoading}
						/>

						<div className="border-t-1 flex justify-between pt-2">
							<div className="flex text-sm">
								{/* <div className='flex items-center mr-4'>
                                    <span className='h-4 w-4 mr-2 bg-red bg-opacity-25 border-1 border-gray-400'></span>
                                    {t('timeRegistration.rejected')}
                                </div>

                                <div className='flex items-center mr-4'>
                                    <span className='h-4 w-4 mr-2 diagonal-bg-danger border-1 border-gray-400'></span>
                                    {t('timeRegistration.locked')}
                                </div>

                                <div className='flex items-center'>
                                    <span className='h-4 w-4 mr-1 bg-gray-100 border-1 border-gray-400'></span>
                                    <span className='h-4 w-4 mr-2 bg-white border-1 border-gray-400'></span>
                                    {t('case.OPEN')}
                                </div> */}
							</div>
							<div>
								<span className="mr-1 font-semibold">{`${t('timeRegistration.totalHours')}:`}</span>
								{data?.reduce((acc, curr) => acc + (curr.hours ?? 0), 0)}
								{typeof accOvetimeHours !== 'undefined' && accOvetimeHours > 0 && (
									<span className="ml-1">
										{accOvetimeHours === 1 ? (
											<Trans
												t={t}
												i18nKey="timeRegistration.overtimeHours_one"
												count={accOvetimeHours}
												values={{
													hours: accOvetimeHours,
												}}
												components={[<strong />]}
											/>
										) : (
											<Trans
												t={t}
												i18nKey="timeRegistration.overtimeHours_many"
												count={accOvetimeHours}
												values={{
													hours: accOvetimeHours,
												}}
												components={[<strong />]}
											/>
										)}
									</span>
								)}
							</div>
						</div>

						<div className="self-end">
							<Button
								primary
								text="timeRegistration.closeDay"
								onClick={() => setShowCloseDayModal(true)}
								className="mt-3"
								disabled={
									data?.some(t => t.timeTrackingStatus === TimeTrackingStatus.REJECTED) ||
									data?.flatMap(a => a.addonLines?.some(t => t?.timeTrackingStatus === TimeTrackingStatus.REJECTED)).includes(true)
								}
							/>
						</div>
					</div>

					<Modal
						title="timeRegistration.registrationExceedsBasisTime"
						visible={showOvertimeWarning}
						close={() => setShowOvertimeWarning(false)}
						nestedModal
						body={
							<div>
								<p>
									{t('timeRegistration.registratingWillExceedBasisTime')} <strong className="mr-1">{basisTimeData?.userBasisTime ?? 0}</strong>
									<span className="mr-1 lowercase">{t('timeRegistration.totalHours')}.</span>
								</p>

								<p className="italic">{t('timeRegistration.checkTimeDraft')}</p>

								<div className="mt-2 flex justify-between">
									<Button
										secondary
										text="common.cancel"
										onClick={() => {
											setShowOvertimeWarning(false);
											alllowOvertime.current = false;
										}}
									/>
									<Button
										primary
										text="common.register"
										onClick={() => {
											alllowOvertime.current = true;
											remoteSubmit();
										}}
									/>
								</div>
							</div>
						}
					/>

					<Modal
						title="timeRegistration.closeDay"
						visible={showCloseDayModal}
						close={() => setShowCloseDayModal(false)}
						nestedModal
						body={
							<div>
								{(userRegisteredHours ?? 0) < (basisTimeData?.userBasisTime ?? 0) ? (
									<div>
										<p>
											<Trans
												t={t}
												i18nKey="timeRegistration.hoursRegisteredOnDay"
												values={{
													hours: userRegisteredHours,
												}}
												components={[<strong />]}
											/>
										</p>

										<p>
											<Trans
												t={t}
												i18nKey="timeRegistration.notEnoughHoursRegistered"
												values={{
													hours: basisTimeData?.userBasisTime ?? 0,
												}}
												components={[<strong />]}
											/>
										</p>
										<p className="mb-4 italic">{t('timeRegistration.useFlexHours')}</p>
									</div>
								) : (
									<div>
										<Trans
											t={t}
											i18nKey="timeRegistration.wantToCloseDay"
											values={{
												date: DateTime.fromISO(date.toISOString()).toFormat('dd/MM/yyyy'),
											}}
										/>
									</div>
								)}

								{typeof accOvetimeHours !== 'undefined' && accOvetimeHours > 0 && (
									<>
										{accOvetimeHours === 1 ? (
											<Trans
												t={t}
												i18nKey="timeRegistration.totalOvertime_one"
												values={{
													hours: accOvetimeHours,
												}}
												components={[<strong />]}
											/>
										) : (
											<Trans
												t={t}
												i18nKey="timeRegistration.totalOvertime_many"
												values={{
													hours: accOvetimeHours,
												}}
												components={[<strong />]}
											/>
										)}
									</>
								)}

								<div className="mt-2 flex justify-between">
									<Button secondary text="common.cancel" onClick={() => setShowCloseDayModal(false)} />
									<Button
										primary
										text="timeRegistration.closeDay"
										onClick={async () => {
											await closeDay();
											close();
										}}
										disabled={(userRegisteredHours ?? 0) < (basisTimeData?.userBasisTime ?? 0)}
									/>
								</div>
							</div>
						}
					/>
				</div>
			}
		/>
	);
};

export default RejectedRegistrationModal;
