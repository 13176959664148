import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import Header from '@ssg/common/Components/Header';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Box from '../../../Components/Layout/Box';
import CaseImageInput from './Inputs/CaseImageInput';
import IndoorClimateMeassurementForm from './Inputs/IndoorClimateMeassurementForm';
import TextAreaInput from './Inputs/TextAreaInput';
import TextInput from './Inputs/TextInput';
import ReportStaticInfo from './ReportStaticInfo';
import useCaseImages from './useCaseImages';
import { useCaseNo } from './useCaseNo';
import { faCheck, faFilePdf, faFileWord } from '@fortawesome/pro-regular-svg-icons';
import { useFlag } from '@unleash/proxy-client-react';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import Loading from '@ssg/common/Components/Loading';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { EsdhFileFragmentFragment, IndoorClimateMeassurementInput, IndoorClimateReportFormDataInput, ReportFormatType, useCreateIndoorClimateReportMutation, useGetCaseReportsAndDocumentsQuery } from '@ssg/common/GraphQL/indexV2';


interface Params {
	id: string;
}
interface ReportError {
	key: keyof IndoorClimateReportFormDataInput;
}
const throwErrorMessage = (key: keyof IndoorClimateReportFormDataInput, reportErrors: ReportError[]): string | undefined => {
	const isError = reportErrors.filter(re => re.key === key).length > 0;
	if (isError) return 'error.required';
	return;
};

const IndoorClimateReport: React.FC = (): React.ReactElement => {
	const { id: caseId } = useParams<Params>();
	const caseNo = useCaseNo(caseId);
	const { t } = useTranslation();
	const history = useHistory();
	const reportFormat = React.useRef<ReportFormatType>(ReportFormatType.Pdf);
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const enableIndoorClimateReportWordButton = useFlag(FeatureFlagEnums.INDOOR_CLIMATE_REPORT_WORD_BUTTON);

	const { loading: caseReportsAndDocumentsLoading } = useGetCaseReportsAndDocumentsQuery({
		variables: {
			id: caseId,
		},
		skip: !caseId || caseId === '',
		onCompleted(data) {
			const formData = removeTypenameGeneric(data.case.caseReports?.indoorClimateReportFormData);
			if (formData) {
				if (formData.purpose) setPurpose(formData.purpose);
				if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));
				if (formData.buildYear) setBuildYear(formData.buildYear);
				if (formData.squareMeters) setSquareMeters(formData.squareMeters);
				if (formData.dailyUsers) setDailyUsers(formData.dailyUsers);
				if (formData.participants) setParticipants(formData.participants);
				if (formData.backgroundStory) setBackgroundStory(formData.backgroundStory);
				if (formData.showLabAnswers) setShowLabAnswers(formData.showLabAnswers);
				if (formData.showDataLog) setShowDataLog(formData.showDataLog);
				if (formData.indoorClimateMeassurements) setIndoorClimateMeassurements(formData.indoorClimateMeassurements);
				if (formData.observations) setObservations(formData.observations);
				if (formData.assessmentAndConclusion) setAssessmentAndConclusion(formData.assessmentAndConclusion);
				if (formData.suggestedActionPlan) setSuggestedActionPlan(formData.suggestedActionPlan);

				if (formData.facadeImage) {
					const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], data.case.documents)[0];
					if (sanitizedFacadeImage) {
						setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, data.case.documents));
					}
				}
				if (formData.floorplanImage) {
					const sanitizedFloorPlanImage = checkFilesExist([formData.floorplanImage], data.case.documents)[0];
					if (sanitizedFloorPlanImage) {
						setFloorplanImage(tryGetFile(sanitizedFloorPlanImage.fileName, data.case.documents));
					}
				}
				const sanitizedOtherImages = checkFilesExist(formData.otherImages ?? [], data.case.documents);
				if (sanitizedOtherImages.length > 0) {
					setOtherImages(
						sanitizedOtherImages.reduce((images: EsdhFileFragmentFragment[], otherImage) => {
							const file = tryGetFile(otherImage.fileName, data.case.documents);
							if (file) {
								images.push(file);
							}
							return images;
						}, []),
					);
				}
			}
		},
	});

	const { images } = useCaseImages(caseNo ?? '');

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${caseId}-indoor-climate-${name}`, initialValue);

	// Form values
	const [facadeImage, setFacadeImage] = useLocalStorage<EsdhFileFragmentFragment | null>('facadeImage-indoorClimate', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));
	const [purpose, setPurpose] = useLocalStorage('purpose-indoorClimate', '');
	const [buildYear, setBuildYear] = useLocalStorage('buildYear-indoorClimate', '');
	const [squareMeters, setSquareMeters] = useLocalStorage('squareMeters-indoorClimate', '');
	const [dailyUsers, setDailyUsers] = useLocalStorage('dailyUsers-indoorClimate', '');
	const [participants, setParticipants] = useLocalStorage('participants-indoorClimate', '');
	const [backgroundStory, setBackgroundStory] = useLocalStorage('backgroundStory-indoorClimate', '');
	const [showLabAnswers, setShowLabAnswers] = useLocalStorage('showLabAnswers-indoorClimate', false);
	const [showDataLog, setShowDataLog] = useLocalStorage('showDataLog-indoorClimate', false);
	const [indoorClimateMeassurements, setIndoorClimateMeassurements] = useLocalStorage<IndoorClimateMeassurementInput[]>('indoorClimateMeassurements-indoorClimate', []);
	const [observations, setObservations] = useLocalStorage('observations-indoorClimate', '');
	const [assessmentAndConclusion, setAssessmentAndConclusion] = useLocalStorage('assessmentAndConclusion-indoorClimate', '');
	const [suggestedActionPlan, setSuggestedActionPlan] = useLocalStorage('suggestedActionPlan-indoorClimate', '');
	const [floorplanImage, setFloorplanImage] = useLocalStorage<EsdhFileFragmentFragment | null>('floorplanImage-indoorClimate', null);
	const [otherImages, setOtherImages] = useLocalStorage<EsdhFileFragmentFragment[]>('otherImages-indoorClimate', []);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const [facadeImageError, setFacadeImageError] = React.useState(false);

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);

			const errors = errorCheck({ buildYear });
			setReportErrors(errors);
		}
	}, [buildYear, facadeImage, submitted]);

	// Setup mutation to create report
	const [createIndoorClimateReport, { loading: submitting }] = useCreateIndoorClimateReportMutation();

	const errorCheck = (formData: Pick<IndoorClimateReportFormDataInput, 'buildYear'>) => {
		const errors: ReportError[] = [];

		if (!formData.buildYear || formData.buildYear.length === 0) {
			errors.push({ key: 'buildYear' });
		}

		return errors;
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;

		setSubmitted(true);

		if (facadeImage == null) {
			setFacadeImageError(true);
		}

		if (facadeImage == null) {
			return;
		}

		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisFloorplanImage = images.find(image => image.name === floorplanImage?.name) ?? floorplanImage;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);


		const formData = {
			inspectionDate: formatDateForInput(new Date(inspectionDate)),
			purpose,
			buildYear,
			squareMeters,
			dailyUsers,
			participants,
			backgroundStory,
			showLabAnswers,
			showDataLog,
			indoorClimateMeassurements,
			observations,
			assessmentAndConclusion,
			suggestedActionPlan,
			facadeImage: fileToInput(thisFacadeImage),
			floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
			otherImages: thisOtherImages.map(fileToInput),
		};

		const errors = errorCheck(formData);
		setReportErrors(errors);

		if (errors.length > 0 || facadeImage == null) {
			return;
		}

		try {
			setLoadingType('CREATE');
			await createIndoorClimateReport({
				variables: {
					caseId,
					formData: formData,
					reportFormat: reportFormat.current,
					onlySave: false,
				},
			});

			// Remove state from local storage
			// clearFacadeImage();
			// clearPurpose();
			// clearBuildYear();
			// clearSquareMeters();
			// clearDailyUsers();
			// clearParticipants();
			// clearBackgroundStory();
			// clearShowLabAnswers();
			// clearShowDataLog();
			// clearIndoorClimateMeassurements();
			// clearObservations();
			// clearAssessmentAndConclusion();
			// clearSuggestedActionPlan();
			// clearFloorplanImage();
			// clearOtherImages();

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}
	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate,
		purpose,
		buildYear,
		squareMeters,
		dailyUsers,
		participants,
		backgroundStory,
		showLabAnswers,
		showDataLog,
		indoorClimateMeassurements,
		observations,
		assessmentAndConclusion,
		suggestedActionPlan,
		facadeImage,
		floorplanImage,
		otherImages]);

	async function saveReport() {
		const thisFacadeImage = facadeImage ? images.find(image => image.name === facadeImage.name) ?? facadeImage : null;
		const thisFloorplanImage = floorplanImage ? images.find(image => image.name === floorplanImage.name) ?? floorplanImage : null;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const formData: IndoorClimateReportFormDataInput = {
			inspectionDate: formatDateForInput(new Date(inspectionDate)),
			purpose,
			buildYear,
			squareMeters,
			dailyUsers,
			participants,
			backgroundStory,
			showLabAnswers,
			showDataLog,
			indoorClimateMeassurements,
			observations,
			assessmentAndConclusion,
			suggestedActionPlan,
			facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
			floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
			otherImages: thisOtherImages.map(fileToInput),
		};
		try {
			setLoadingType('SAVE');
			await createIndoorClimateReport({
				variables: {
					caseId,
					formData: formData,
					reportFormat: reportFormat.current,
					onlySave: true,
				},
			});
			setSaved(true);

		} catch (e) {
			console.error(e);
		}
	}
	if (caseReportsAndDocumentsLoading) return <Loading />;

	return (
		<div>
			<Header title="reports.reportTypes.indoorClimate" backButton />

			<div className="space-y-8 px-10 pb-10 text-sm">
				<ReportStaticInfo caseId={caseId} />

				<Box full>
					{caseNo != null && (
						<>
							<CaseImageInput
								caseNo={caseNo}
								headerTitle="reports.facadeImage"
								title={t('reports.uploadFacadeImage')}
								noSelectedImageText={t('reports.noFacadeImage')}
								//selectedImages={[facadeImage].filter(notNull)}
								selectedImagesNames={facadeImage !== null ? [facadeImage.name] : []}
								onImageSelect={img => setFacadeImage(img)}
							/>
							{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
						</>
					)}

					<Datepicker title="reports.inspectionDate" value={inspectionDate} onChange={e => setInspectionDate(e.target.value)} name="reports.inspectionDate" />

					<TextAreaInput label={t('reports.purpose')} placeholder={t('reports.purposePlaceholder')} value={purpose} onChange={setPurpose} />

					<TextAreaInput label={t('reports.backgroundStory')} placeholder={t('reports.backgroundStoryIndoorClimatePlaceholder')} value={backgroundStory} onChange={setBackgroundStory} />

					<TextInput
						label={t('reports.buildYear')}
						placeholder={t('reports.buildYearPlaceholder')}
						value={buildYear}
						onChange={setBuildYear}
						errorMessage={throwErrorMessage('buildYear', reportErrors)}
					/>

					<TextInput label={t('reports.squareMeters')} placeholder={t('reports.squareMetersPlaceholder')} value={squareMeters} onChange={setSquareMeters} />

					<TextInput label={t('reports.dailyUsers')} placeholder={t('reports.dailyUsersPlaceholder')} value={dailyUsers} onChange={setDailyUsers} />

					<TextAreaInput label={t('reports.participants')} value={participants} placeholder={t('reports.participantsPlaceholder')} onChange={setParticipants} />

					<TextAreaInput label={t('reports.observations')} placeholder={t('reports.observationsIndoorClimatePlaceholder')} value={observations} onChange={setObservations} />

					<h2 className="text-blue text-xl font-bold">{t('reports.indoorClimateMeassurements')}</h2>

					<Checkbox name="showLabData" title="reports.showLabData" onChange={e => setShowLabAnswers(e.currentTarget.checked)} checkedControlled={showLabAnswers} />

					<Checkbox name="showDataLog" title="reports.showDataLog" onChange={e => setShowDataLog(e.currentTarget.checked)} checkedControlled={showDataLog} />
					{/* TODO: Målinger og resultater */}

					<IndoorClimateMeassurementForm indoorClimateMeassurements={indoorClimateMeassurements} onChange={setIndoorClimateMeassurements} />

					<TextAreaInput
						label={t('reports.assessmentAndConclusion')}
						placeholder={t('reports.assessmentAndConclusionIndoorClimatePlaceholder')}
						value={assessmentAndConclusion}
						onChange={setAssessmentAndConclusion}
					/>

					<TextAreaInput
						label={t('reports.suggestedActionPlan')}
						placeholder={t('reports.suggestedActionPlanIndoorClimatePlaceholder')}
						value={suggestedActionPlan}
						onChange={setSuggestedActionPlan}
					/>

					{caseNo != null && (
						<>
							<CaseImageInput
								headerTitle="reports.floorPlanImage"
								title={t('reports.uploadFloorplanImage')}
								noSelectedImageText={t('reports.noFloorplanImage')}
								caseNo={caseNo}
								//selectedImages={[floorplanImage].filter(notNull)}
								selectedImagesNames={floorplanImage !== null ? [floorplanImage.name] : []}
								onImageSelect={setFloorplanImage}
								onImageRemove={() => setFloorplanImage(null)}
							/>
						</>
					)}

					{/* TODO: Skimmelsvampe? */}

					{caseNo != null && (
						<CaseImageInput
							headerTitle="reports.extraImages"
							title={t('reports.uploadExtraImages')}
							noSelectedImageText={t('reports.noExtraImages')}
							caseNo={caseNo}
							onImageSelect={image => setOtherImages(current => toggleArrayValue(current, image, img => img.url))}
							selectedImagesNames={otherImages.map(oi => oi.name)}
						/>
					)}
				</Box>

				<div className="ml-4 my-2 mr-6 flex flex-row space-x-2">
					<Button
						icon={faFilePdf}
						onClick={() => submitReport(ReportFormatType.Pdf)}
						disabled={submitting}
						loading={submitting && reportFormat.current === ReportFormatType.Pdf && loadingType === 'CREATE'}
						success
						text="reports.createPdfReport"
					/>
					{enableIndoorClimateReportWordButton && (
						<Button
							icon={faFileWord}
							onClick={() => submitReport(ReportFormatType.Word)}
							disabled={submitting}
							loading={submitting && reportFormat.current === ReportFormatType.Word && loadingType === 'CREATE'}
							success
							text="reports.createWordReport"
						/>
					)}
					{saveButtonReportsFlag &&
						<Button disabled={submitting} loading={submitting && loadingType === 'SAVE'} primary onClick={() => saveReport()} text={saved ? '' : 'reports.saveReport'} icon={saved ? faCheck : undefined} />
					}
				</div>
			</div>
		</div>
	);
};

export default IndoorClimateReport;
