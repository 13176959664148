import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { BaseMachineFragment } from '../GraphQL';
import QRCode from 'react-qr-code';
import classNames from 'classnames';
import Button from './Button';
import Modal from './Modal';

enum LabelSize {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
}

interface Props {
	machines: BaseMachineFragment[];
	buttonText?: string;
	disabled?: boolean;
	prefixFlag: boolean;
}

const PrintMachineLabel: React.FC<Props> = ({ machines, buttonText, disabled = false, prefixFlag }): React.ReactElement => {
	const { t } = useTranslation();

	const [showModal, setShowModal] = React.useState(false);
	const [print, setPrint] = React.useState(false);
	const [size, setSize] = React.useState<LabelSize>();

	const componentRef = React.useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	React.useEffect(() => {
		if (print) {
			handlePrint && handlePrint();
			setPrint(false);
		}
	}, [handlePrint, print, size]);

	return (
		<div>
			<div className="hidden">
				<div ref={componentRef}>
					{/* BIG LABEL SIZE: 36mm x 90mm */}
					{size === LabelSize.LARGE &&
						machines.map((machine, i) => (
							<div key={machine.erpReferenceNo} className={classNames('flex flex-row justify-between p-2', { 'mb-1': machines.length - 1 !== i })}>
								<div className="space-y-1 py-2 pr-1 pl-1">
									<div>
										<span className="text-2xl font-semibold">{machine.erpReferenceNo}</span>
									</div>
									<div>
										<p>{machine.name}</p>
										<p>{machine.modelNumber}</p>
									</div>
								</div>
								<div className="py-2">
									<QRCode size={104} value={prefixFlag ? machine.erpReferenceNo : 'ma:' + machine.erpReferenceNo} />
								</div>
							</div>
						))}

					{/* MEDIUM LABEL SIZE: 18mm x 60mm */}
					{size === LabelSize.MEDIUM &&
						machines.map((machine, i) => (
							<div key={machine.erpReferenceNo} className={classNames('flex flex-row justify-between p-1', { 'mb-1': machines.length - 1 !== i })}>
								<div className="pr-1">
									<div>
										<span className="text-sm font-semibold">{machine.erpReferenceNo}</span>
									</div>
									<div>
										<p className="mr-2 text-xs">{machine.name}</p>
										<p className="text-xs">{machine.modelNumber}</p>
									</div>
								</div>
								<div>
									<QRCode size={59} value={prefixFlag ? machine.erpReferenceNo : 'ma:' + machine.erpReferenceNo} />
								</div>
							</div>
						))}

					{/* SMALL LABEL SIZE: 18mm x 20mm */}
					{size === LabelSize.SMALL &&
						machines.map((machine, i) => (
							<div key={machine.erpReferenceNo} className={classNames('flex flex-row p-1')}>
								<div
									className={classNames({
										'-mt-1': machines.length !== 1 && machines.length - 1 === i,
									})}
								>
									<div>
										<p className="text-3xs -mt-1 font-semibold">{machine.erpReferenceNo}</p>
									</div>
									<div className="flex">
										<QRCode size={48} value={prefixFlag ? machine.erpReferenceNo : 'ma:' + machine.erpReferenceNo} className="-mb-1" />
										<p className="text-vertical text-3xs">{machine.modelNumber.slice(0, 11)}</p>
									</div>
								</div>
								<div className="">
									<p
										className={classNames('text-vertical text-3xs -mr-2', {
											'mt-px': machines.length !== 1 && machines.length - 1 === i,
										})}
									>
										{machine.name}
									</p>
								</div>
							</div>
						))}
				</div>
			</div>

			<Button icon={faPrint} onClick={() => setShowModal(true)} primary text={buttonText} disabled={disabled} />

			{showModal && (
				<Modal
					title="machines.selectLabelSize"
					visible={showModal}
					close={() => setShowModal(false)}
					body={
						<div className="flex justify-between">
							<Button
								secondary
								text="36mm x 90mm"
								onClick={() => {
									setSize(LabelSize.LARGE);
									setPrint(true);
									setShowModal(false);
								}}
							/>

							<Button
								secondary
								text="18mm x 60mm"
								onClick={() => {
									setSize(LabelSize.MEDIUM);
									setPrint(true);
									setShowModal(false);
								}}
							/>

							<Button
								secondary
								text="18mm x 20mm"
								onClick={() => {
									setSize(LabelSize.SMALL);
									setPrint(true);
									setShowModal(false);
								}}
							/>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default PrintMachineLabel;
