import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceAssortment, InvoiceDraft } from './InvoiceModal';
import SummaryTable from './SummaryTable';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { GetDebitor_debitor, GetInvoices_invoices_lines, InvoiceCategory } from '../../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { getEnvFeeOnInvoice, hasMaximumEnvironmentalFeeAmountForDebitor, shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { SelectOption } from '@ssg/common/Helpers/Helpers';

// Hide Comments from the user as they are for internal purposes
export function filterInvoiceLines(lines: GetInvoices_invoices_lines[]): GetInvoices_invoices_lines[] {
	return lines.filter(line => line.type !== 'Comment');
}

export function addUOM(lines: GetInvoices_invoices_lines[], assortments: InvoiceAssortment[], screeningLines: SelectOption[]): (GetInvoices_invoices_lines & { uom?: string })[] {
	return lines.map(l => {
		const UOMForLine = l.screeningLine
			? screeningLines.find(a => l.screeningLineId === a.value)?.label
			: assortments.find(a => a.no === l.no)?.uom;
		return {
			...l,
			uom: UOMForLine,
		};
	});
}
interface Props {
	invoiceDraft: InvoiceDraft;
	debitor: GetDebitor_debitor;
	category: string;
	assortments: InvoiceAssortment[];
	screeningLines: SelectOption[];
}

const InvoiceStepView: React.FC<Props> = ({ invoiceDraft, debitor, category, assortments, screeningLines }) => {
	const { t } = useTranslation();
	const enabledEnvironmentalFeeFlag = useFlag(FeatureFlagEnums.ENVIRONMENTAL_FEE);

	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);
	const hasMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);
	const totalPrice = invoiceDraft.lines.reduce((price, line) => price + (line.lineAmount ?? 0), 0);
	const totalEnvFee = getEnvFeeOnInvoice(invoiceDraft.lines, debitor);

	return (
		<>
			<div className="mt-2 mb-4 space-x-4">
				<span className="inline-block rounded-full bg-gray-200 px-3 py-1 font-semibold">{invoiceDraft.erpReferenceNo}</span>
				<span className="inline-block rounded-full bg-gray-200 px-3 py-1 font-semibold">{t(`case.invoice.categories.${invoiceDraft.category}`)}</span>
				<span className="inline-block rounded-full bg-gray-200 px-3 py-1 font-semibold">{t(`case.invoice.statuses.${invoiceDraft.status}`)}</span>
			</div>

			{invoiceDraft.rejectionReason && (
				<div className="mb-3 text-sm">
					<h2 className="font-semibold">{t('myPage.invoiceApproval.rejectionComment')}</h2>
					<p className="text-red font-semibold">{invoiceDraft.rejectionReason}</p>
				</div>
			)}

			{invoiceDraft.invoiceDescription !== null && invoiceDraft.invoiceDescription.length > 0 && (
				<div className="text-blue text-sm">
					<h2 className="font-semibold">{t('common.description')}</h2>
					<p className="overflow-y-auto" style={{ maxHeight: '10rem' }}>
						{invoiceDraft.invoiceDescription}
					</p>
				</div>
			)}

			{invoiceDraft.lines.length === 0 ? (
				<p>{t('case.invoice.noLines')}</p>
			) : (
				<>
					<SummaryTable lines={addUOM(filterInvoiceLines(invoiceDraft.lines), assortments, screeningLines)} isScreening={invoiceDraft.category === InvoiceCategory.SCREENING} />
					{enabledEnvironmentalFeeFlag && shouldCalculateEnvFeeForDebitor && (
						<div className="mt-2 text-right">
							<p>
								{t('case.invoice.envFee')}: {debitor.environmentFeePercentage}% | {t('case.invoice.maxEnvFee')}: {hasMaxEnvFeeAmount ? debitor.maxEnvironmentFeeAmount : 'N/A'}
							</p>
							<p>
								{t('case.invoice.envFeeTotal')}: {addThousandSeperator(totalEnvFee)}
							</p>
						</div>
					)}
					<p className="my-2 text-right">
						{t('case.invoice.line.total')} {addThousandSeperator(totalPrice)}
					</p>
				</>
			)}
		</>
	);
};

export default InvoiceStepView;
